.faqs{
    padding-left: 0px;
    .body{
        font-family: Barlow;
        font-size: 1.25px;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0em;
        text-align: left;

    }

    .accordion-button{
        &:not(.collapsed) {
          &::after {
            background-image: escape-svg($paar-accordion-button-active-alt-icon);
          }
        }
        &::after {
          background-image: escape-svg($paar-accordion-button-alt-icon);
        }
    }

    .header{
        font-family: Barlow;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3.125rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;

    }

    .menu{
        font-family:"Rubik";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.313rem;
        letter-spacing: 0.03em;
        text-align: left;
        color: $paar-pink;
        padding: 1rem;
        td{
            color: $paar-pink;
            border:none;
             border-bottom: 1px dotted  $paar-pink;

        }


    }
    .info{
        padding-top: 8.5em;
        padding-bottom: 8.5em;

    }
    background-color: $offwhite;

    .banner{
        background-color: $warm-gray;
        padding-left: 0px;
        padding-top: 5em;
        overflow: hidden;
        .banner-illu.mobile{
            padding-bottom: 1em;

        }

        .banner-copy.mobile{
            padding-left: 0px;
            padding-top: 5em;


            h2{
                font-family: Libre Baskerville;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: bold;
                line-height: 2.5rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
            p{
                font-family: Barlow;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: normal;
                line-height: 2.125rem;
                letter-spacing: 0.01em;
                text-align: left;
                color: $white;
            }
        }
        .empty{
            height: 15vh;
        }
        .banner-copy{
            h2{
                font-family: Libre Baskerville;
                font-size: 3.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
            p{
                font-family: Barlow;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.5rem;
                letter-spacing: 0em;
                text-align: left;
                color: $white;
            }
        }
    }

    .accordion {
         border-bottom: 1px solid  $maroon;
         background-color: $offwhite;
         .accordion-header,.accordion-button,h2{
            font-size: 1.75rem;
            color: $paar-pink;
            font-family: "Barlow";
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: 3.125rem;
            color: $maroon;
            background-color: $offwhite;
        }
      }
      &.mobile{
        .accordion {
            .accordion-header,.accordion-button,h2{
            letter-spacing: 0.02em;
            line-height: 2.0rem;
            }

        }
    }
    .accordion .accordion-item{
    border: 0px;
    border-top: 1px solid  $maroon;
    background-color: $offwhite;
    }

    .accordion .accordion-item .accordion-body{
        border: 0px;
        border-top: 1px solid  $maroon;
        background-color: $offwhite;
    }

    p {
        font-size: 1.25vw;
        color: $black;
        font-family: "Barlow";
        font-weight: 400;
        color: $black;

    }
}

#banner-faq-illu{
    height: auto;
   width:100%;
}

#banner-faq-illu-mobile{
    height: auto;
    width:115%;
}

.mobile{
    padding-right: 0px;
    margin-right: 0px;
}

#question1, #question2, #question3, #question4, #question5 {
    transform-origin: 50% 50%;
    animation: bubblePulse 10s infinite;
}
@keyframes bubblePulse {
    0%,100% {-webkit-transform:scale(1);}
    50% {-webkit-transform:scale(.9);}
}
.accordion-button:focus{
    border-color: none !important;
    box-shadow: none !important;
}
@media only screen and (max-width: 576px) {
    .faqs {
        .info{
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
        .accordion-button{
            font-size: 14px !important;
            padding: 10px !important;
            line-height: 1.5rem !important;
        }
        .row{
            display: inline !important;
        }
        .col-6{
            width:100% !important
        }
        button{
            width: auto !important;
        }
        .accordion-body{
            font-size: 12px;
            padding: 8px !important;
        }
    }

}
.report {
  $maroon: #64002a;
  $pink: #ee2b79;
  padding: 0px;
  margin: 0px;
  .content {
    padding: 0px;
    margin: 0px;
  }
  .banner {
    margin: 0px;
    padding: 0px;
    height: 579px;
    width: 100%;
    background-color: white;
    .state-name {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: $pink;
    }
    .prison-detail {
      width: "177px";
      padding-top: 8px;
    }
    .img5 {
      height: 12.67px;
      width: 8.86px;
      margin-right: 12px;
    }
    h4 {
      padding-top: 30px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;

      color: $maroon;
    }
    h6 {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $pink;
    }
    h1 {
      font-family: "Libre Baskerville";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;

      color: $maroon;
    }
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: $maroon;
    }
  }
  .reportnav {
    margin: 0;
    padding-left: 0px;
    padding-bottom: 2.5vh;
    * {
      padding-left: 0px;
    }
    a {
      font-family: Barlow;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.125rem;
      letter-spacing: 0em;
      text-align: left;
      color: $maroon;
    }
    div,
    p {
      font-family: Barlow;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.125rem;
      letter-spacing: 0em;
      text-align: left;
      color: $maroon;
    }
    a.active {
      color: $paar-pink;
    }
    .report-category {
      background-color: $white-2;
      margin: 0px;
      padding-right: 0px;
    }
    .report-category-item.row {
      margin: 0px;
    }
    .report-link.mobile {
      border-bottom: 0px;
    }
    .report-link.active {
      color: $paar-pink;
      border-bottom: 3px solid $paar-pink;
    }
    .report-link {
      border-bottom: 3px solid $maroon;
      margin-right: 40px;
    }
  }
  .navbreadcrumb {
    .breadcrumb {
      padding-top: 2.5vh;
    }
    .breadcrumb-item a,
    li {
      text-decoration: none;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $paar-pink;
    }
    .breadcrumb-item.active {
      color: $maroon;
    }
    .img {
      position: absolute;
      width: 224px;
      height: 189px;
      left: 284px;
      top: 109px;
    }
  }
  .page-title {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height */
    color: $maroon;
  }
  .w-390 {
    width: 394px !important;
  }
  .data-overview {
    display: flex;
    padding-left: 106px;
    height: 158px;
    background: $maroon;
    align-items: center;
    gap: 60px;
    .data-heading {
      width: 189px;
      padding-right: 145px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;

      color: #ffffff;
    }
    .flex-col {
      width: 189px;
    }
    .heading {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: #ffffff;
    }
    .detail {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;

      color: #ffffff;
    }
  }

  .report-page {
    padding-top: 25px;
    padding-left: 1px;

    .col {
      margin-bottom: 30px;
      width: 380px;
      height: fit-content;
      .report-contact {
        width: "134px";
        padding-top: 8px;
      }
      .img1 {
        height: 12.67px;
        width: 12.67px;
        margin-right: 12px;
      }
      .img2 {
        height: 12.67px;
        width: 8.86px;
        margin-right: 12px;
      }
      .img3 {
        height: 11px;
        width: 14px;
        margin-right: 12px;
      }
      .telephone-data {
        width: 125px;
        padding-top: 8px;
      }
      .location-data {
        width: 227px;
        padding-top: 8px;
      }
      .email-data {
        width: 125px;
        padding-top: 6px;
      }

      h6 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        padding: 0;

        color: $maroon;
      }
      span {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: $maroon;
      }
    }
    td {
      width: 150px !important;
      font-weight: 450;
      color: $maroon;
    }
    .td-width {
      width: 271px !important;
    }
    a {
      color: $maroon;
    }
    b {
      font-weight: 400;
      font-size: 20px;
      color: $maroon;
    }
    h2 {
      font-family: Barlow;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      text-align: left;
      color: $maroon;
      padding-bottom: 15px;
    }
    h3 {
      font-family: Barlow;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 800;
      line-height: 1.75rem;
      letter-spacing: 0.02em;
      text-align: left;
      color: $maroon;
    }
    h5 {
      font-family: Barlow;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      letter-spacing: 0em;
      text-align: left;
      color: $maroon;
    }
    .stats-row {
      border-left: 1px solid $maroon;
    }
    .copy-col {
      margin-left: 10px;
      .copy-text {
        font-family: Barlow;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0em;
        text-align: left;
        color: $maroon;
        padding-left: 0px;
      }
      h6 {
        font-family: Barlow;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.125rem;
        letter-spacing: 0em;
        text-align: left;
        color: $maroon;
        padding-left: 0px;
      }
      .copy-link {
        font-family:"Rubik";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.313rem;
        letter-spacing: 0.03em;
        text-align: left;
        color: $paar-pink;
        padding-left: 0px;
      }
    }
    .image-row-item {
      padding-bottom: 25px !important;
      a {
        font-family:"Rubik";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.313rem;
        letter-spacing: 0.03em;
        text-align: left;
        padding-top: 25px;
        color: $paar-pink;
      }
    }
    .empty {
      height: 2.3625em;
    }
}
  @media only screen and (max-width: 576px) {
    .navbreadcrumb {
      .img {
        position: absolute;
        width: 204px;
        height: 145px;
        left: 192px;
        top: 741px;
      }
    }
    .overview-div {
      background: $maroon;
      position: relative;
      margin: auto;
      width: 1100px;
      max-width: 100%;
      overflow-x: scroll;
    }
    .banner {
      height: fit-content;
      padding-top: 83px;
    }
  }
}

@keyframes kite-rotate {
    0% {
      transform: rotateY(0deg);
    }
    20% {
        transform: rotateY(15deg);
    }
    40% {
        transform: rotateY(0deg);
    }
    80% {
      transform: rotateY(-15deg);
    }
    100% {
        transform: rotateY(0deg);
      }
  }

@keyframes dandelion{
    0% {
        transform: translateY(0px);
      }
      20% {
        transform: translateY(1px);
      }
      40% {
        transform: translateY(0px);
      }
      80% {
        transform: rotate3D(0,0,0,0deg);
      }
      100% {
          transform: rotate3D(0,0,0,0deg);
        }
}

@keyframes kite-rotate-logo {
    0% {
        transform: rotateX(10deg);
    }
    20% {
        transform: rotateX(5deg);
    }
    40% {
        transform: rotateX(0deg);
    }
    80% {
        transform: rotateX(-5deg);
    }
    100% {
        transform: rotateX(0deg);
        }
}

@keyframes plane-rotate {
    0% {
      transform: rotate(0deg);
    }
    20% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(0deg);
    }
    80% {
      transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
      }
}


@keyframes cloud-wiggle {
    0% {
      transform: translateX(0em);
    }
    20% {
        transform: translateY(1em);
    }
    40% {
        transform: translateX(1em);
    }
    80% {
      transform: translateY(-1em);
    }
    100% {
        transform: translateX(0em);
      }
}

@keyframes cloud-wiggle2 {
    0% {
      transform: translateY(0em);
    }
    20% {
        transform: translateX(1em);
    }
    25% {
        transform: translateX(0em);
    }
    30% {
      transform: translateY(0em);
    }
    40% {
        transform: translateY(-1em);
      }
    50% {
        transform: translateY(0em);
    }
    60% {
        transform: translateX(1em);
    }
    70% {
        transform: translateX(0em);
    }
    80% {
        transform: translateY(2em);
    }
    90% {
        transform: translateY(-2em);
    }
    100% {
        transform: translateY(0em);
    }
}
@keyframes cloud-wiggle3 {
  0% {
    transform: translateX(0em);
  }
  20% {
      transform: translateY(1em);
  }
  25% {
      transform: translateX(0em);
  }
  30% {
    transform: translateY(0em);
  }
  40% {
      transform: translateX(-1em);
    }
  50% {
      transform: translateY(0em);
  }
  60% {
      transform: translateX(1em);
  }
  70% {
      transform: translateY(0em);
  }
  80% {
      transform: translateX(2em);
  }
  90% {
      transform: translateY(-2em);
  }
  100% {
      transform: translateX(0em);
  }
}

@keyframes bird-wiggle {
    0% {
      transform: translate(0em);
    }
    20% {
        transform: translate(1em);
    }
    40% {
        transform: translate(0em);
    }
    80% {
      transform: translate(-1em);
    }
    100% {
        transform: translate(0em);
      }
}

@keyframes paar-fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes butterfly{
    0% {
        transform: translateY(-30vh);
      }
      50% {
        transform: translateY(-25vh);
      }   
      100% {
        transform: translateY(-30vh);
    }
}

// flying
@keyframes flying {
	0%,	100% {
    top: 16vh;
    left: -23vw;
    transform: rotate(-15deg);
	}
  50% {
    top: 11vh;
    left: -21vw;
    transform: rotate(-5deg);
	}
}

// flyingPlane
@keyframes flyingPlane {
	0%,	100% {
    // top: -128px;
    // left: -30px;
    // transform: rotate(8deg);
    transform: translate(-11%, -73%) rotate(0deg);
	}
  50% {
    // top: -30px;
    // left: -95px;
    transform: translate(-35%, -30%) rotate(-12deg);
	}
}

@keyframes flyingPlaneRightToLeft {
	0%,	100% {
    left: 0;
    transform: rotate(0deg);
	}
  50% {
    left: -95px;
    transform: rotate(-12deg);
	}
}


// Carousel 3 kiteFlying
@keyframes kiteFlying {
	0%,	100% {
		transform: translateY(4.5em) translateX(0.5em) rotate(8deg);
	}
  50% {
    transform: translateY(7.5em) translateX(-1.5em) rotate(5deg);
	}
}

// Bird1 Animation
#floatingbird1 {
  position: relative;
  animation: bird-wiggle 4s linear infinite;
}
#floatingbird2 {
  position: relative;
  animation: bird-wiggle 3.5s linear infinite;
}
.bird-wings1 {
  position: relative;
  transform: rotateZ(-5deg) rotateX(-35deg) translateX(21px) translateY(5px) translateZ(0);
  animation: bird1-wings1 4s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes bird1-wings1 {
  40%{
    transform: rotateZ(-5deg) rotateX(-60deg) translateX(21px) translateY(0px) translateZ(0);
  }
  80%{
    transform: rotateZ(-5deg) rotateX(-35deg) translateX(21px) translateY(5px) translateZ(0);
  }
}

.bird-wings2 {
  position: relative;
  transform: rotateZ(2deg) rotateX(15deg) translateX(5px) translateY(0px) translateZ(0);
  animation: bird1-wings2 6s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes bird1-wings2  {
  40%{
    transform: rotateZ(2deg) rotateX(15deg) translateX(5px) translateY(0px) translateZ(0);
  }
  80%{
    transform: rotateZ(0deg) rotateX(0deg) translateX(5px) translateY(0px) translateZ(0);
  }
}

// Bird2 Animation 
.bird2-wings1 {
    position: relative;
    transform: rotateZ(-5deg) rotateX(-55deg) translateX(-3px) translateY(0px) translateZ(0);
    animation: bird2-wings1 3s linear infinite;
    transform-origin: 50% 50%;
}

@keyframes bird2-wings1 {
  40%{
    transform: rotateZ(0deg) rotateX(0deg) translateX(-5px) translateY(0px) translateZ(0);
  }
  80%{
     transform: rotateZ(-5deg) rotateX(-55deg) translateX(-3px) translateY(0px) translateZ(0);
  }
}

.bird2-wings2{
    position: relative;
    transform: rotateZ(3deg) rotateX(5deg) translateX(5px) translateY(7px) translateZ(0);
    animation: bird2-wings2 2.5s linear infinite;
    transform-origin: 50% 50%;
}

@keyframes bird2-wings2 {
  40%{
    transform: rotateZ(3deg) rotateX(5deg) translateX(5px) translateY(5px) translateZ(0);
  }
  80%{
    transform: rotateZ(0deg) rotateX(0deg) translateX(5px) translateY(7px) translateZ(0);
  }
}
@keyframes floatAnim {
  0%, 100% {
    transform: translate(1px,10px) rotate(-2deg);
  }
  50% {
    transform: translate(10px,20px) rotate(-7deg);
  }
}

// Kite Floating Animation
@keyframes KitefloatAnim {
  0%, 100% {
    transform: translate(0px,4px) rotate(-1deg);
  }
  50% {
    transform: translate(10px,8px) rotate(-7deg);
  }
}
@keyframes KitefloatAnim1 {
  0%, 100% {
    transform: translate(-12px,-8px) rotate(5deg);
  }
  50% {
    transform: translate(7px,15px) rotate(-5deg);
  }
}
@keyframes KitefloatAnim2 {
	0%,	100% {
    transform: translate(5px,-26px) rotate(-15deg);
	}
  50% {
    transform: translate(3px,-11px)  rotate(-5deg);
	}
}

// Puzzle Floating Animation 
@keyframes puzzlefloatAnim {
  0%, 100% {
  transform: translate(0px,0px) rotate(0deg);
  }
  50% {
  transform: translate(5px,-5px) rotate(-7deg);
  }
}

// Hand Floating Animation
@keyframes handFloatAnim {
  0%, 100% {
    transform: translate(0px,0px) rotate(0deg);
  }
  50% {
    transform: translate(10px,30px) rotate(-5deg);
  }
}

// Butterfly Animation
#butterfly-prison {
  transform-origin:  50% 50%;
  animation: butterflyAnim 20s infinite;
  position: absolute;
  top: 18%;
  left: -13%;
      // .wings_left {
      //     transform-origin: 0% 45%;
      //     animation: flap_wings_left 2000ms alternate infinite;
      // }
      // .wings_right {
      //  transform-origin:  0% 55%;
      //    animation: flap_wings_right 2000ms alternate infinite;
      // }
}
@keyframes butterflyAnim {
    0%, 100% {
          transform-origin:  50% 50%;
        transform: Scale(1) SkewY(0) SkewX(0) translateX(0) translateY(-20px)  translateZ(0);
      }
      25% {
          transform-origin:  50% 50%;
          transform: Scale(0.8) SkewY(-15deg) SkewX(-15deg)  translateX(-110px) translateY(40px) translateZ(0);
      }
      50% {
          transform-origin:  50% 50%;
          transform: Scale(1) SkewY(0) SkewX(0) translateX(0) translateY(-20px) translateZ(0);
      }
       75% {
          transform-origin:  50% 50%;
          transform: Scale(0.8) SkewY(-15deg) SkewX(-15deg) translateX(-110px) translateY(40px) translateZ(0);
      }
}

  @keyframes flap_wings_left {
    0%, 100% {
        transform:  Scale(1)  SkewY(0) SkewX(0) translateY(0) translateX(0) translateZ(0);
      }
  
      100% {
       transform: Scale(0.8) SkewY(-15deg) SkewX(-15deg) translateY(45px) translateX(55px) translateZ(0);
      }
  }
  
  @keyframes flap_wings_right {
      0% {
        transform:  Scale(1) SkewY(0) SkewX(0) translateY(0) translateX(0) translateZ(0);
      }
  
      100% {
       
         transform: Scale(0.8) SkewY(-22deg) SkewX(-15deg) translateY(109px) translateX(118px) translateZ(0);
      }
  }


  #whole-flower {
    animation: bounce 1s ease-in-out -5s both infinite;
  }

@keyframes bounce{
  50% {transform:translateY(20px);}
  100%{transform:translateY(0px);}
}
@import "./colors";
@import "./fonts";
@import "./screen-sizes";
@import "./sizes";

$paar-accordion-button-icon:         url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 10L17 10' stroke='#EC1A75' stroke-width='0.75'/><path d='M10 3L10 17' stroke='#EC1A75' stroke-width='0.75'/><circle cx='10' cy='10' r='9.625' stroke='#EC1A75' stroke-width='0.75'/></svg>");
$paar-accordion-button-active-icon:  url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 10L17 10' stroke='#EC1A75' stroke-width='0.75'/><circle cx='10' cy='10' r='9.625' stroke='#EC1A75' stroke-width='0.75'/></svg>");


$paar-accordion-button-alt-icon: url("data:image/svg+xml,<svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='17' y1='2.18557e-08' x2='17' y2='33' stroke='#EC1A75'/><line y1='16' x2='33' y2='16' stroke='#EC1A75'/></svg>");
$paar-accordion-button-active-alt-icon: url("data:image/svg+xml,<svg width='33' height='2' viewBox='0 0 33 2' fill='none' xmlns='http://www.w3.org/2000/svg'><line y1='1' x2='33' y2='1' stroke='#EC1A75'/></svg>");

$paar-carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg width='39' height='77' viewBox='0 0 39 77' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M38 1L2 36.25L38 76' stroke='#9F9A93' stroke-width='1.5'/></svg>");
$paar-carousel-control-next-icon-bg: url("data:image/svg+xml,<svg width='39' height='77' viewBox='0 0 39 77' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L37 36.25L1 76' stroke='#9F9A93' stroke-width='1.5'/></svg>");


$paar-file-carousel-control-prev-icon-bg: url("./icons/prev-slide.svg");
$paar-file-carousel-control-next-icon-bg: url("./icons/prev-slide.svg");


$paar-carousel-control-prev-icon-bg-alt: url("data:image/svg+xml,<svg width='39' height='77' viewBox='0 0 39 77' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M38 1L2 36.25L38 76' stroke='#F3E6D5' stroke-width='1.5'/></svg>");
$paar-carousel-control-next-icon-bg-alt: url("data:image/svg+xml,<svg width='39' height='77' viewBox='0 0 39 77' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L37 36.25L1 76' stroke='#F3E6D5' stroke-width='1.5'/></svg>");

$paar-hamburger-icon: url("data:image/svg+xml,<svg width='25' height='21' viewBox='0 0 25 21' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 0.5H25' stroke='#EC1A75'/><path d='M0.5 10H25' stroke='#EC1A75'/><path d='M0.5 20H25' stroke='#EC1A75'/></svg>");
//$paar-hamburger-icon: url("../../public/imgs/icons/icon-hamburger.svg");


$paar-card-img-bg: url("data:image/svg+xml,<svg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='100' cy='100' r='99.5' stroke='#EC1A75'/></svg>");
$primary: $maroon;
$secondary: $paar-pink;
$paar-show-more: url("data:image/svg+xml,<svg width='25' height='13' viewBox='0 0 25 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L12.8286 12L24 1' stroke='#EC1A75'/></svg>");
.btn-primary:focus{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}

.btn-primary:active{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}
.btn-primary:hover{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}
.btn-primary:enabled{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}
.btn-primary.active{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}
a.active, a.btn:focus{
    box-shadow: none;
    border: none;
    background: none;
    border: 0px;
}

@import "./common/common";
@import "./components/main";

@import "./utils/z-index";
@import "./utils/stickynav";
@import "./utils/misc";
@import "./utils/animations";
@import "./utils/parallax";



.stories-wrapper{
    padding:50px 150px;
    @media screen and (max-width:940px) {
        padding:50px 20px;
    }
    @media screen and (max-width:600px) {
        padding: 30px 30px 30px 20px;
    }
    .header{
        font-family: "Rubik";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0.03em;
        text-align: left;
        color:#64002A;
        padding-left: 10px;
        @media screen and (max-width:992px) {
            font-size: 20px;
        }
    }
    .news-row{
        display:flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .card-body {
            padding-left: 0;
        }
        .story-card{
            width: 30%; 
            margin-left: 10px;
            padding-bottom: 70px;
            @media screen and (max-width:600px) {
                width: 100%;
                padding-bottom: 40px;
            }
            .story-img {
                height: 200px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                @media screen and (min-width: 600px) {
                    height: 150px;
                }
                @media screen and (min-width: 1200px) {
                    height: 250px;
                }
            }
        }
        .story-date{
            font-family: "Libre Baskerville";
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color:$maroon;
        }
        .story-title{
            font-family: "Barlow";
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.02em;
            text-align: left;
            color:$maroon;
        }
        .read-more{
            font-family: "Rubik";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.03em;
            text-align: left;
            color:$paar-pink;
            margin-top:40px;
            cursor: pointer;

            @media screen and (max-width:600px) {
                margin-top:20px;
            }
            img{
                width:10px;
            }
        }
    }
    
    .show-toggle{
        font-family: "Barlow";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        color:$paar-pink;
        cursor: pointer;
        img{
            width:20px;
            margin-left: 17px;
            margin-top: -6px;
        }
    }
}

.card-img-top{
    width: 100%;
}

/*********************************************/
/*          PAAR COLORS                      */
/*********************************************/

$maroon: #64002A;
$paar-pink: #EC1A75;
$paar-pink-light: #FF3F92;
$offwhite: #F3E6D5;
$beige: #E2C999;
$gradient: #c4c4c4;
$light-gray: #CFC9C0;
$medium-gray: #9F9A93;
$white: #ffffff;
$white-2: #FFFBED;
$warm-gray: #C3B29E;
$twitter: #47C6D8;
$dark-beige: #C6AE87;
$dark-skin: #B59570;
$body-bg: #E5E5E5;
$body-color: #ffffff;
$dropdown: #FFF4E7;
$white-3 :#EADDCA;


/*********************************************/
/*          MISC COLORS                      */
/*********************************************/


@import "./team-land";
@import "./team-paar";
@import "./team-donors";
@import "./team-friends";

.title{
    font-family: Libre Baskerville;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.688rem;
    letter-spacing: 0.03em;
    text-align: left;
    color:$maroon
}

.carousel-control-prev {
    left: -12.5%;
}
.carousel-control-next {
    width: max-content;
    right: -8%;
}
// img{
//     transform: translateX(1vw);
// }
.flip-img {
    background-color: transparent;
    width: fit-content;
    perspective: 1000px;
  }
  
.flip-img-inner {
    position: relative;
    // width: 100%;
    // height: 100%;
    align-content: flex-start;
    //text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-img:hover .flip-img-inner {
    transform-origin:center center; 
    transform: rotateY(180deg);
}

.flip-img-front, .flip-img-back {
    //position: relative;
    // width: 100%;
    // height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-img-front {

}

.flip-img-hidden{
    display: none;
}

.flip-img-back {
    
    transform: rotateY(180deg);
}

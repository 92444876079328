.herotron {
    background-color: $offwhite;
    //min-height: 85vh;
    height: 85vh;

    padding-bottom: 5vh;
    h1 {
        font-family: "Rubik";
        font-size: 2.125rem;
        color:$maroon;
        font-style: normal;
        font-weight: bold;
        line-height: 2.5rem;
        letter-spacing: 0.02em;
    }
    p {
        font-family: "Barlow" !important;
        font-weight: 400 !important;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2.125rem;
        letter-spacing: 0.01em;

    }
    #paar{
        color:$paar-pink;
    }
   
    .illu.overlap{
        // .fg{
        //     position: relative;
        //     top: 10vh;
        //     left: -20vw;
        //     z-index:2500;
        //     height:  30vh;
        //     width:  35vh;
        //     animation: kite-rotate 4s infinite;
        .fg{
            position: relative;
            top: 10vh;
            left: -20vw;
            z-index:2500;
            height:  30vh;
            width:  35vh;
            animation: flying 7s ease-in-out infinite;
        }
        // }
        // .bg{
        //     position: relative;
        //     //top: 30vh;
        //     left: -25vw;
        //     z-index:2000;
        // }
    
     
       
    }
   

    
    .vr{
        height: 100%;
    }
    
}
.herotron.mobile{
    height: max-content;
    max-height: max-content;
}
 #kite_mobile{
    height: auto;
    width:95%;
 }

#p_window_hand{
    height: auto;
    width:75%;
}
#p_window_eye{
    height: auto;
    width:40%;
}
.content{
    padding-top:10vh;
    min-height: 100vh;
}

// .copy{
//     height: 75vh;
// }
.rule{
    height: 25vh;
}

.illu.row{
    width: 100%;
    height: 50vh;
}

#p-animated-kite-window{
    position: absolute;
   // z-index: 5000;
    #pink-kite{
        position: relative;
        transform-origin: 50% 50%;
        animation: KitefloatAnim1 5s ease-in-out infinite;
    }
}


.mobile{
    overflow-x: hidden;
}
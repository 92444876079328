.footer {
    background-color:$maroon;
    color: $offwhite, !important;
    height: 100%;
    padding-top: 2.5vh; 
    padding-bottom: 2.5vh; 
    

    
}


.footer {
    background-color: $maroon; 
    font-family:"Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.313rem;
    letter-spacing: 0.03em;
    color: $offwhite;
    .navbar-brand {
      color: $offwhite;
    }
  
    .nav-item {
      padding-left: 0px;
    }
      .nav-item a{
        padding-left: 0px;
      }
      .nav-link {
        color: $offwhite;
  
        &:hover,
        &:focus {
          color: $paar-pink;
        }
  
      }
    .social{
      #footer-social-title{
        padding-bottom: 1vh;
      }
      #social-insta:hover{
        g#Group{
          #path_1_2{
            fill: $paar-pink;
          }
        }
      }

    }


    .nav-text {
      color: $offwhite;
    }
    // .social.nav .nav-item .navbar-brand{
    //   padding-bottom: 0.3125rem;
    // }
    .footer-logo{
      // transform: translateY(-1vh);
    }
}

.empty{
  
  height: 5vh;
}
.t_and_c{
    .banner{
        background-color: $offwhite;
        .title{
            padding-top: 20vh;
            padding-bottom: 5vh;

        }
        h2{
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $maroon;
            
        }
        min-height: 25vh;
    }
    .content
    {
        min-height: max-content;

        background-color: $white;
        p{
                
            //styleName: infographic_2;
                font-family: Barlow;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0em;
                text-align: left;
                
            }

    }
}

.privacy{
    .banner{
        background-color: $offwhite;
        .title{
            padding-top: 20vh;
            padding-bottom: 5vh;

        }
        h2{
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $maroon;
            
        }
        min-height: 25vh;
    }
    .content
    {
        min-height: max-content;
        background-color: $white;
        p{
                
            //styleName: infographic_2;
                font-family: Barlow;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0em;
                text-align: left;
                
        }
        a {
            color: $paar-pink;
            text-decoration-color: $paar-pink;
            
            &:link,&:visited{
                color: $paar-pink;
                text-decoration-color: $paar-pink;   
            }
            
            &:hover{
                color: $maroon;
                text-decoration-color: $maroon;   
            }
        }

    }
}

.team.friends{
    min-height: 66vh;

    padding-top: 7.5vh; 
    padding-bottom: 7.5vh; 
    padding-right: 7.5vw; 

    .title{
        background-color:  $light-gray;
        padding-right: 10%;
    }
    background-color: $light-gray;
    

    // .friends_container{
    //     width: 100%;
    //     .title{
    //         padding-left: 10%;
    //     }
    // }
    .card{
        background-color: $light-gray;
        border: 0px;
    
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $white;

        &:hover,&:focus{
            color: $offwhite;
        }



    }

    
    .card-img-top{
        width: 12.5rem;    
        height: 12.5rem;  
    }
    
    .card img{
        border-radius: 50%;
        object-fit: cover;
        
        
    }
    .card-body{

        border:0px;
        padding-left:0px;
        
        //flex-direction: row-reverse;
         vertical-align: bottom;
      }
    .card-text{
       //styleName: body_copy_3;
       font-family: Barlow;
       font-size: 1.25rem;
       font-style: normal;
       font-weight: 400;
       line-height: 2rem;
       letter-spacing: 0em;
       text-align: left;
       color: $black;
    }
    .card-subtitle{

        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $maroon;
        padding-bottom: 0.25em; 
        border-bottom: 0.02em solid $maroon;


    }
    .card-title{
            
        //styleName: team name;
        font-family:"Rubik";
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.75rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;

    }
    .btn.btn-primary.details{
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $white;
        background-color: $light-gray;
        border-color: $light-gray;
        // border:0px;
        // padding:0px;
        
        &:hover,&:focus{
            color: $maroon;
        }
        border:0px;
        padding:0px;
        
    
    }


}



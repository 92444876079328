
.parallax {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    //Webkit fix
    //-webkit-perspective: 100%;
    //perspective: 100%;
    //display:flex; 
    // flex-direction:row-reverse;
    // scroll-behavior: smooth;
    //-webkit-perspective-origin-x:100% ;
    //perspective: 100%;
    
    scroll-behavior: smooth;

    //hide scroll bar by making it transparent and 0px wide
    &::-webkit-scrollbar {
        //left: 1000vw;
        scroll-behavior: smooth;
        width: 0px; // remove scrollbar space 
        background: transparent; // just make scrollbar invisible 
    } 
    pointer-events: none;
}


.parallax__layer {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //flex-direction:column-reverse;
    // display:flex; 
    // flex-direction:row-reverse;

    //Webkit Fix
    //transform-origin: -50%;
    //-webkit-transform-origin-x: -50%;
    scroll-behavior: smooth;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;


}
.parallax__layer--base {
    transform: translateZ(0);
    z-index: 3;
    scroll-behavior: smooth;

}

// .parallax__layer--fore {
//     -webkit-transform: translateZ(1px) scale(0.7);
//     transform: translateZ(1px) scale(0.7);
//     z-index: 1;
//   }


.parallax__layer--back {
    transform: translateZ(-1px) scale(2);
    z-index: 2;
    scroll-behavior: smooth;

}


.parallax__layer--deep {
    transform: translateZ(-2px);
    z-index: 1;
    scroll-behavior: smooth;

}


.parallax__group {
    position: relative;
    height: 100vh;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    

}


.position-relative {
    position: relative;
}
.ourstory{
    user-select: none;
    background-color: $medium-gray;
    color: $white;
    font-family: "Barlow";
    font-style: normal;
    height: 102vh;
   // max-height: fit-content;


    // .content{
    //     transform: translate(0,50%)
    // }
    .empty 
    {
        height: 15vh;
    }

    .empty-bottom
    {
        height: 10vh;
    }
    h4 {
        font-family: "Rubik";
        font-size: 1.25rem;
        font-weight: 700;
        color:$white;
        line-height: 2.75rem;
        
    }
    p {
        font-family: "Barlow";
        font-size: 2.25rem;
        color:$white;
        font-weight: "normal";
        line-height: 2.5rem;

    }
  
    .ourstory-copy.mobile{
        height: fit-content;

    }

   

    
}


.ourstory.mobile{
    height: fit-content;
    .copy{
        height: fit-content;
    }
    overflow: hidden;
    padding-bottom: 4vh;
    padding-top: 4vh;
    p{
        font-size: 1.375rem;
    }
    
}

.banner.mobile{
      
    padding-right:0px;
}


p#tag-mobile{
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 2.125rem;
}



.illu.mobile.large{
    max-width: fit-content;
    padding-bottom:0px;
    width: 75%;
}
.content{
    padding-bottom: 5%
}
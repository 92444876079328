.header {
  background-color: $white;
  color: $maroon;
  z-index: 3000;
}
.navbar-light {
  font-family:"Rubik";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.0625em;
  letter-spacing: 0.03em;
  text-align: left;
  color: $maroon;
  .navbar-brand {
    color: $maroon;
  }

  .navbar-nav {
    .nav-item{
      padding-left: 1.5vw;

    }
    .nav-link {
      color: $maroon !important;
      &:hover,
      &:focus {
        color: $paar-pink;
      }
    }
  }

  .navbar-text {
    color: $maroon;
  }
  #nav-logo {
    height: 49px;
  }
  .navbar-toggler-icon {
    background-image: escape-svg($paar-hamburger-icon);
    color: $paar-pink;
    border: 0px;
  }
  .navbar-toggler {
    border: 0px;
    box-shadow: none;
  }
}

.navheader {
  padding-left: 45px;
  padding-right: 45px;
  .drop-down {
    margin-bottom: 0px;
    .dropdown-menu {
      background-color: white;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      width: fit-content !important;
      --bs-dropdown-link-active-bg: white !important;
      margin-top: 21px !important;
      padding-bottom: 12px;
      .dropdown-item {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #64002a;
      }
    }
  }
  .primary.nav-link {
    height: auto;
    color: #ee2b79 !important;
    border: 1px solid;
    border-radius: 210px;
  }
  .secondary.nav-link {
    height: auto;
    background-color: #ee2b79 !important;
    border: 1px solid #ee2b79;
    border-radius: 210px;
  }
  .donate-button {
    color: white;
  }
}
.mobile-view {
  width: 285px;
  padding-left: 1px;
  padding-right: 1px;
  .drop-down {
    margin-bottom: 0px;
    .dropdown-menu {
      margin-top: -7px;
      border: none;
      padding-left: 64px;
      background-color: white;
      --bs-dropdown-link-active-bg: white !important;
      width: fit-content !important;
      .dropdown-item {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #64002a;
      }
    }
  }
  .primary.nav-link {
    height: auto;
    color: #ee2b79 !important;
    border: 1px solid;
    border-radius: 210px;
    margin-bottom: 10px;
  }
  .secondary.nav-link {
    height: auto;
    background-color: #ee2b79 !important;
    border: 1px solid #ee2b79;
    border-radius: 210px;
  }
  .donate-button {
    color: white;
  }
}

.team.land{
    
    height: 100vh;
    // width: 100%;
        // .copy{
        //     position: relative;
        //     height: 33%;
        // }
        .copy{
            padding-bottom: 2.5vh;
            padding-left: 0px;
            height: fit-content;
        }
        .copy h1,span.heading{ 
            font-family: Libre Baskerville;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.5rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $white;
        }
        .copy .heading{
            color: $paar-pink;
        }
        .copy p{
            color: $white;
            font-family: Barlow;
            font-size: 34px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            text-align: left;
            letter-spacing: 0.01em;


        }
        .copy span{
            color: $paar-pink;



        }
 
    
}
.paarresources {
    background-color: $white;
    padding-left: 0;
    margin-left: 0;
    // padding-right: 0;
    // margin-right: 0;
    min-height: fit-content;

    .banner {
        // height: 70vh;
        // .row{
        //     max-height: max-content;

        // }
        padding-right: 0;
        //margin-right: 0;
        overflow: hidden;

        .banner-copy.mobile {
            padding-top: 15vh;

            h2 {
                font-family: Libre Baskerville;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: bold;
                line-height: 2.5rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }

            p {

                //styleName: infographic_2;
                font-family: Barlow;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: normal;
                line-height: 2.125rem;
                letter-spacing: 0.01em;
                text-align: left;
                color: $white;


            }
        }

        .banner-illu.mobile {
            padding-left: 0px;
            // padding-right: 0px;
            // margin-right:0px;
            margin-left: 0px;
            padding-top: 0.5em;
            padding-bottom: 0.5em;


        }

        // .banner-illu{
        //     padding-top: 0;
        //     max-width: fit-content;
        // }
        background-color: $warm-gray;

        h2 {
            color: $maroon;
            //styleName: Header Banner;
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: left;
        }

        p {
            color: $white;
            //styleName: infographic_2;
            font-family: Barlow;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.5rem;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .content {
        padding-top: 2.5vh;

        .info {
            padding-top: 1vh;
        }

        #resources-nav {
            margin-top: 2vh;
            margin-bottom: 2vh;
            padding-left: 0px;

            a,
            .nav-item.dropdown {
                padding-left: 0px !important;
            }

            .dropdown-toggle::after {
                background: none;
                content: "";
                display: none;
            }

            .dropdown-item.close {
                background-color: $paar-pink;

            }

            .dropdown-item.close a {
                color: $dropdown;

            }

            .nav-item {
                padding-right: 7vw;
                margin-right: 1vw;
                padding-left: 0px !important;

                //border-bottom: 0.25em solid $maroon;
                color: $maroon  !important;
                //styleName: resources_header;
                font-family: Barlow;
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.125rem;
                letter-spacing: 0em;
                text-align: left;

                &:hover,
                &:focus {
                    color: $paar-pink  !important;

                }
            }

            .nav-link {
                padding: 0;
                color: $maroon  !important;
                //styleName: resources_header;
                font-family: Barlow;
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.125rem;
                letter-spacing: 0em;
                text-align: left;

                &:hover,
                &:focus {
                    color: $paar-pink  !important;

                }

            }

            a {
                color: $maroon  !important;
            }

            &.wide .nav-item {
                border-bottom: 0.25em solid $maroon;

                &:hover,
                &:focus {
                    color: $paar-pink  !important;
                    border-bottom: 0.25em solid $paar-pink;
                }

                &:active,
                &:checked {
                    color: $paar-pink  !important;
                    border-bottom: 0.25em solid $paar-pink;
                }
            }

            &.collapsed {
                border-bottom: none;

                //background-color:$dropdown;
                .dropdown-menu {
                    padding-top: 0px;
                }

                .dropdown-item {
                    color: $maroon;
                    height: 6vh;
                    border-radius: 0;
                    padding-left: 6vw !important;
                    border-bottom: 1px solid $maroon;

                    &:hover,
                    &:focus {
                        color: $maroon  !important;
                        border-bottom: 0.25em solid $paar-pink;
                        background-color: inherit;
                    }

                    &:active,
                    &:checked {
                        color: $maroon  !important;
                        border-bottom: 0.25em solid $paar-pink;
                        background-color: $dropdown;
                    }

                }

                .dropdown-item.active {
                    background-color: $dropdown;
                    color: $black;

                }


            }


        }

    }


    .content {
        margin-bottom: 7.5vh;
    }


    .reports-items {

        margin-bottom: 4vh;

        height: max-content;
        min-height: 50vh;

        .report-card {
            background-color: $beige;
            box-shadow: 0.75vw 1.25vh $medium-gray;
            min-height: 100%;
            min-width: 100%;

            // flex-direction: row;
            // flex-grow: 2;
            .card-body {
                margin-left: 2.5vh;
            }

            .card-subtitle {
                margin-top: 2.5vh;
                //styleName: caption;
                font-family:"Rubik";
                color: $maroon;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: normal;
                line-height: 1.063rem;
                letter-spacing: 0.03em;
                text-align: left;
                text-transform: uppercase;
            }

            .card-title {
                margin-top: 2.5vh;
                color: $maroon;
                //styleName: resources_title;
                font-family: Barlow;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: normal;
                line-height: 2.5rem;
                //letter-spacing: 0.02em;
                text-align: left;
                overflow-wrap: normal;

            }

            .card-footer {
                margin-bottom: 2.5vh;
                margin-left: 2.5vh;

            }

            .btn {
                background-color: $beige;
                border: 0;
                border-radius: 0;

            }

            &:hover {
                box-shadow: 0.75vw 1.25vh $maroon;
            }

        }

        .col-3 {
            min-height: 100%;
            padding-left: 0px;
            margin-top: 4vh;
            // margin-right: 7.5vw;

        }



    }

    .inthenews-page .inthenews-items {
        user-select: none;
        margin-bottom: 4vh;

        .card {
            position: relative;
            border-radius: 0px;
        }

        .card-img-top {
            position: relative;
            border-radius: 0px;
        }

        .card-body {
            margin-top: 2.5vh;
            padding-left: 0px;
        }

        .btn {
            background: none;
            border: none;
            padding-left: 0px;
        }

        .card-subtitle {
            //styleName: caption;
            font-family: Libre Baskerville;
            color: $maroon;
            font-size: 0.875rem;
            font-style: italic;
            font-weight: normal;
            line-height: 1.5rem;
            //letter-spacing: 0.03em;
            text-align: left;
            //text-transform: uppercase;    
        }

        .tag {
            position: absolute;
            opacity: 0.3;
            width: 97%;
            height: 255px;
            background-color: $paar-pink  !important;
        }

        .inthenews-card:hover .tag {
            opacity: 0;
        }

        .card-title {
            color: $maroon;
            //styleName: resources_title;
            font-family: Barlow;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2rem;
            text-align: left;
            overflow-wrap: normal;
            letter-spacing: 0.02em;


        }

        .inthenews.card {
            //    margin-right:1vw;
        }

        .inthenews-card.card .card-body {
            //margin-left: 0px !important;

            //padding-left: 0px !important;
        }

        .inthenews-card .card-body,
        .inthenews-card .card-title,
        .inthenews-card .card-subtitle {
            border: 0px !important;
            //margin-left: 0px !important;

            //padding-left: 0px !important;
        }
    }

    .films-page {
        border: none;
        // margin-left: 0px !important;

        // padding-left: 0px !important;
        .card-img-top {
            border-radius: 0px;
        }

        .films-items {
            //height: max-content;
            border: 0px !important;
            margin-bottom: 4vh;

            //padding-left: 0px !important;
            .film-card.card {
                margin-right: 1vw;
            }

            .card-body {
                padding-left: 0px;
            }

            .card-subtitle {
                //styleName: caption;
                font-family: Libre Baskerville;
                color: $maroon;
                font-size: 0.875rem;
                font-style: italic;
                font-weight: normal;
                line-height: 1.5rem;
                // letter-spacing: 0em;
                text-align: left;
                padding-left: 0px;



            }


            .card-text {

                color: $maroon;

                font-family: Libre Baskerville;
                font-size: 0.875rem;
                font-style: italic;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0em;
                text-align: left;

                margin: 5px 0;
                text-align: left;
                padding-left: 0px;

            }

            .card-title {

                color: $maroon;
                //styleName: resources_title;
                font-family: Barlow;
                font-size: 1.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                letter-spacing: 0.02em;
                text-align: left;
                overflow-wrap: normal;
                padding-left: 0px;

            }

            .card-body {
                margin-left: 0px !important;

                //padding-left: 0px !important;
            }

            .card-body,
            .film-card .card-title,
            .card-subtitle {
                border: 0px !important;
                margin-left: 0px !important;

                //padding-left: 0px !important;
            }
        }


    }

    .card {
        margin-left: 0px;
        padding-left: 0px !important;
        border: none;
        border-radius: 0px;
        margin-bottom: 1.5vh;

        a {
            color: $maroon;
            //styleName: footer menu items;
            font-family:"Rubik";
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.313rem;
            letter-spacing: 0.03em;
            text-align: left;


        }

        &:hover * {
            color: $paar-pink  !important;
        }

        .card-img-overlay {
            height: fit-content;
            width: fit-content;
            transform: translate(100%, 50%);
        }

        @include media-breakpoint-down(xs) {
            width: 95%;
        }

        @include media-breakpoint-up(md) {
            width: 30%;
        }

        @include media-breakpoint-only(sm) {
            width: 45%;
        }

    }

}


.dropdown-menu {
    background: $offwhite;
    width: 100vw !important;
    //left:-6vw !important;
    padding-bottom: 0px;
    border-radius: 0;


}

.empty {
    height: 10vh;
}

.card {
    border: none;
    border-radius: 0px;
}

.banner-illu-mobile {
    width: 110%;
    height: auto;
}

// .banner-illu{
//     margin-right:0px;
//     padding-right:0px;
// }

.banner-illu.large {
    padding-top: 0px;
}

#banner-report {
    margin-top: 4vh;
}

.banner-illu.col {
    padding-top: 0px;
    margin-top: 0px;
}

// .banner.large{
//     //padding-right: 0px;
//     margin-right:0px;
//     // width: 110%;
//     // height: auto;
// }


#banner-report-large {
    // margin-top:2vh;
}

.banner-illu.row {
    height: fit-content;
    padding-top: 0px;
}

.banner-illu.col-2 {
    height: fit-content;
    padding-top: 0px;
}

// .banner-illu{
//     padding-right: 0px;
//     margin-right:0px;

// }

.reports-page {
    padding-left: 0px;
    margin-left: 0px;
}


.reports-items .col-md-3 {
    padding-left: 0px;
    //margin-left:0px;
}

.tab-content {
    padding-left: 0px;
    margin-left: 0px;
}

.inthenews-page {
    padding-left: 0.75em;
}

.films-page {
    padding-left: 0.75em;
}

.show-more {
    color: $paar-pink;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 2.125rem;
    height: 5vh;
    width: 50%;

    &:hover,
    &:focus {
        background-color: $dropdown;
        color: $maroon;
        box-shadow: none;
        border: none;
    }

    &:active,
    &:checked {
        background-color: $dropdown;
        color: $maroon;
        box-shadow: none;
        border: none;
    }

    &:after {
        content: "";
        background-image: escape-svg($paar-show-more);
        background-color: $white;
        box-shadow: none;
        border: none;
        width: 100%;
        height: auto;
    }
}

// Resource Centre ANimation
.fillColor {
    animation: fillColor 3s ease-in-out infinite;

    &.report2 {
        animation-delay: 1s;
    }
}

#Vector_749,
#Vector_799,
#Vector_849 {
    animation: fillColor 2s ease-in-out infinite;
}

#Vector_799 {
    animation-delay: 1s;
}

#Vector_849 {
    animation-delay: 1.5s;
}

#newsletter {
    animation: floatAnim 7s ease-in-out infinite;
}

#banner-report {
    animation: handFloatAnim 7s ease-in-out infinite;
}

#newspaper {
    animation: handFloatAnim 10s ease-in-out infinite;
}

@keyframes fillColor {

    0%,
    100% {
        fill: #EC1A75
    }

    50% {
        fill: grey
    }
}

.resources-nav {}

.filter {
    margin-right: 0px;

}

.dropdown {
    padding-left: 0px;
    padding-right: 0px;
    //margin-left: 12px;
    margin-bottom: 12px;
    margin-right: 0px;

    .open-button {
        padding-left: 0px !important;
    }
}

.cj-dropdown-list {
    width: 100vw !important;
    padding-left: 0px !important;
}

.offcanvas {
    height: max-content;
    color: $maroon  !important;
    background-color: $offwhite;

    .no-border {
        border-bottom: 0px !important;
    }

    .offcanvas-title {
        padding-left: 12px;
    }

    .offcanvas-body {
        //padding-left: 0px;

        .category-elements {
            margin-left: 12px;
            padding-left: 0px;
        }

    }
}
.contact{

    background-color: $offwhite;
    padding-left:0px;

    .banner{
        background-color: $warm-gray;
        overflow: hidden;
        min-height: 35vh;
        padding-top: 5em;

        .copy{
            height: max-content;
            h2{
                font-family: Libre Baskerville;
                font-size: 3.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;

            }
            p{
                font-family: Barlow;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.5rem;
                letter-spacing: 0em;
                text-align: left;
                color: $white;
            }
        }
        .copy.mobile{
            padding-left: 0px;
            padding-top: 5em;
            padding-bottom: 2.5em;
            h2{
                font-family: Libre Baskerville;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: bold;
                line-height: 2.5rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
            p{

                font-family: Barlow;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: normal;
                line-height: 2.125rem;
                letter-spacing: 0.01em;
                text-align: left;
                color: $white;


            }
        }

    }
    .empty{
        height: 2.5vh;
        padding-right: 0px;
        margin-right: 0px;
    }
    .header-1{
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.125rem;
        letter-spacing: 0em;
        text-align: left;
        color: $maroon;
    }
    .contactus{
        background-color:$offwhite;


        .form-control{
            background-color:$offwhite;
            border-color:$maroon;
            color: $maroon;
            border-radius:0rem;

        }
        .form-control:focus{
            background: rgba($offwhite, 0.25);
            border-color: rgba($light-gray, 0.25);
            box-shadow:  rgba($maroon, 0.25);

        }

    }
    .btn {

        background-color:$paar-pink;
        color: $offwhite;
        width:10vw;
        border-radius:3.125rem;
        border-color: $paar-pink;
        outline-color:$paar-pink;
        font-family: Barlow;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.688rem;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 1vw !important;
    }
    .header-2{
        font-family: Barlow;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3.125rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;
    }
    p{
        font-family: Barlow;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        letter-spacing: 0em;
        text-align: left;
        color: $black;

    }
    .info{
        padding-right: 0px;
        margin-right: 0px;
        .empty{
            height: 5vh;
            padding-right: 0px;
            margin-right:0px;
        }
    }
    .contact-form{
        padding-top: 5em;
        padding-right: 0px;
        margin-right:0px;
    }
    .field-office{
            p{
                line-height: 2.125rem;
            }
    }
    .map{
        padding-top: 2.5em;
        padding-bottom: 2.5em;
    }

}

.banner-illu.col{
    padding-top:7.5vh;
    margin-top: 7.5vh;

}
#banner-contact-illu2{
    padding-top:2.2em;
    height: auto;
    width: 160%;
    position: relative;
    animation: flyingPlaneRightToLeft 8s infinite;
}
#banner-contact-illu{
    height: auto;
    width:150%;
}

#banner-contact-illu-mobile{
    height: auto;
    width:120%;

}

.mobile{
    padding-right: 0px;
    margin-right: 0px;
}
.contact-form{
    margin-left: 20px;
    margin-right: 20px;
}
.contact button{
    width: max-content !important;
}
.heading{
    margin-left: 20px;
    margin-right: 20px ;
}
@media only screen and (max-width: 576px) {
    .contact-form{
        padding-top: 2rem !important;
        .form-fields{
            display: inline !important;
        }
    }
    .contact-form{
        margin-left: 0px !important;
        margin-right: 20px;
    }
    .heading{
        h2{
        font-size: 24px !important;
        }
        p{
            font-size: 18px !important;
        }
    }
}

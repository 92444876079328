.error{
    background-color:$offwhite;
    height: 100vh;
    padding-top:30vh;
    
    .body{
        padding-top:0;
        $color:$maroon;
    }
    .copy-header,h1,h2{
        $color:$maroon;
        font-family: Libre Baskerville;
        font-size: 3.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4.375rem;
        letter-spacing: 0.03em;
        text-align: left;
    }
    p{
        font-family: Barlow;
        font-size:1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0em;
        text-align: left;

    }
    .btn {
        background-color:$paar-pink;
        color: $offwhite;
        width:10vw;
        border-radius:3.125rem;
        border-color: $paar-pink;
        outline-color:$paar-pink;
        font-family: Barlow;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.688rem;
        letter-spacing: 0em;
        text-align: center;
    }
    .btn-primary:hover{
        background-color:$maroon;
    }

}
.cycleofchange{
    background-color: $light-gray;
    color: $light-gray;
    font-style: normal;
        .empty{
            height: 15vh;
        }
        .stat-empty-row{
            height: 5vh;
            background-color: $light-gray;
        }
        h4 {
            font-size: 3.5em;
            color: $paar-pink;
            font-family: "Libre Baskerville";
            font-weight: "bold";
            font-style: "normal";
            letter-spacing: 0.03em;
            //line-height: 4.375em;

        }
        .stats-header{
            color: $white-2;
            //styleName: infographic_data;
            font-family: Barlow;
            font-size: 9rem;
            font-style: normal;
            font-weight: 200;
            line-height: 10.813rem;
            letter-spacing: 0em;
            text-align: left;
           // transform: translateY(-5vh);
        }
        .stats-header2{
            color: $maroon;
            //styleName: infographic_2;
            //transform: translateY(-1vh);
            font-family: Barlow;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.5rem;
            letter-spacing: 0em;
            text-align: left;
        }

        .stats-body{
            color: $maroon;
            //styleName: bodycopy_bold_3;
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2.125rem;
            letter-spacing: 0em;
            text-align: left;

        }
        .stats-body1{
            color: $paar-pink;
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;
        }
        .stats-body2{
            color: $white-2;
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;
        }
        .stats-body3{
            color: $white-2;
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;
        }
        .stats-body-copy{
            //styleName: body copy 2;
            color: $black;
            font-family: Barlow;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem;
            text-align: left;

        }
        .stats-disclaimer{
            font-family: "Work Sans";
            color: $maroon;
            font-size: 0.813rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.938rem;
            letter-spacing: -0.02em;
            text-align: left;

        }
        .stats-header-unit{
            //styleName: infographics_data_2;
            color: $white-2;
            font-family: Barlow;
            font-size: 3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 3.125rem;
            letter-spacing: 0em;
            text-align: left;

        }
        .glyph-data{

            //styleName: infographics_data_2;
            padding-left: 1vw;
            //padding-top: 1vw;
            
            color: $white-2;
            font-family: Barlow;
            font-size: 3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4rem;
            letter-spacing: 0em;
            text-align: left;

        }

        .glyph{
            width: 100%;
            height: auto;
            padding-bottom: 1vh; 
        }
 
    
        .illu-line{
            height:7.5vh;
        }
        // .glyph-data{
        //     transform: translateY(0.6vh);
        // }

    
}
.glyph.mobile{
    width: 15%;
    height: auto;
    padding-left: 0px;
}
#cycle-land-illu{
    width: 100%;
    height: auto;
}
.cycleofchange.flowchart{
    background-color: $warm-gray;

    .carousel{
        padding-top:5vh;
        & *{border-color:none;}
        .carousel-control-prev, .carousel-control-next{
            border-color:none;
        }
        .carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus
        {
            color: $white-2;
        }
        background-color: inherit;
        .card {


            .card-header{
                background-color: rgba(0,0,0,0);
                box-shadow: none;
                font-family:"Rubik";
                font-style: normal;
                font-weight: bold;
                font-size: 1.5rem;
                line-height: 1.75rem;
                letter-spacing: 0.02em;
                color: $white-2;
              }
              p{
                    color: $maroon;
              }
        }
    }
    .coc-flowchart{
        padding-top: 7.5vh;
        .coc-infoslide{
          

        }
    }
}



// #chart-glyph-mobile{
//     width: 100%;
//     height: auto;
// }

.illu.info.mobile{
    width: 200%;
    height: auto;
    z-index: 10;
}
.stats-body-copy.mobile{
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.625rem;
}
.stats-body-header.mobile{
    font-family: Libre Baskerville;
        font-style: normal;
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 2.75rem;
        /* or 111% */

letter-spacing: 0.03em;

}

.stats-header2.mobile{
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 2.25rem;
    line-height: 2.5rem;

}
.stats-header.mobile{
    font-family: Barlow;
    font-style: normal;
    font-weight: 200;
    font-size: 6.25rem;
    line-height: 7.5rem;
    padding-left: 0px;
    margin-right: 0px;
}

.content.mobile{
    padding-right: 0px;

}

.banner.mobile{
    padding-right: 0px;
}


.glyph-row-mobile{
    padding-right: 0px;
}

.stat-mobile{
    //padding-right:0px;
    //margin-right:0px;
    //width: fit-content;
}



.stats-header-mobile{
    
}

.stats-rajasthan.mobile{
    margin-left: 0px;
}

.stats-banner-mobile{
    margin-left: 0px;
    padding-left: 0px;
}

.cycleofchange.flowchart{
    overflow:hidden;
    min-height: 75vh;
    .content{
        min-height: 75vh;
        padding-top: 5vh;
    }
    &.wide{
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
}

// .cycleofchange.flowchart .content{
//     height: 75vh;
// }

.cycleofchange.land{

    .content{
    min-height: 100vh;

    padding-top: 10vh;
    padding-bottom: 10vh;
    .copy{
        height: max-content;
     .stats-body-copy{
         padding-top: 3vh;
     }   
    }
}
}
.cycleofchange.infrographic1{
    .content{
        min-height: 100vh;

    }    
    .stats .stat-empty-row{
        height: 5vh;
    }
    .stat-empty-row{
        height: 5vh;
    }
    &.mobile{
        .rajasthan.stats-disclaimer{
            padding-bottom: 3vh; 
        }
    }   
    .banner.mobile{
        padding-bottom: 3vh; 

    }
}


.stat-empty-row{
    height: 5vh !important;
}
.stats-body{
    padding-top: 5vh;
}
.infrographic2{
    .content{
        min-height: 100vh;
        padding-top: 0px;
    }
    .stats-disclaimer{
        padding-top:1.5vh;
    }
    .stats-body-copy{
        padding-top:2.5vh;
    }
}
.infrographic2.mobile{
    padding-top: 5vh;
    .stats.mobile{
        padding-top: 3vh;
        
    }
    #chart-glyph-mobile{
        padding-bottom: 3vh;
     
    }
    .stats-body.mobile * {
        padding-bottom: 1vh;

    }
}

.stats-body.india{
    padding-top:3vh;
}

.mobile.glyph-stats * {
   // padding-left:0px;
}
.glyph-data.mobile{
    padding-left:0px;
}

.coc-infoslide {
    .card, .card-header,.card-body *{
    border: 0px;
    box-shadow: none;
    }
    .carousel-control-prev, .carousel-control-next{
        border-color:none;
        background-color:none;
        box-shadow:none;

    }
    .card{
        background-color: $warm-gray;
        //opacity: 1;
        .card-title.mobile-h3{
            color: $white-2;

            font-family:"Rubik";
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.75rem;
            letter-spacing: 0.02em;
            color: $white-2;
            background-color: $warm-gray;

          }
          .card-header{
            color: $white-2;

            background-color: $warm-gray;

          }
          p{
                color: $maroon;
          }
         
            
          
    }
} 
.coc-infoslide{
    & .carousel .carousel-item{
        min-height: fit-content !important;   
    }
    .carousel.mobile .card .card-title.mobile-h3{
    color: $white-2;
    }
}

.cycleofchange.budget{
    .content{
        min-height: 100vh;
    }
    &.mobile{
        .hand-glyphs.illu{
            padding-top: 2%;
        }

        // padding-left:0px;
        // .banner.mobile .stats-header{
        //    margin-left:0px;
        // }
        // .hand-glyphs.rajasthan {
        //     padding-bottom: 3vh;
        // }
        // .india.stats-disclaimer {
        //     padding-bottom: 5vh;
        // }
    }
}


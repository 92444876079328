.feed{
    justify-content: center;
    align-items: center;
    height: 5vh;
    background-color: $white-2;
    color: $maroon;
    //styleName: menu items;
    font-family:"Rubik";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.063rem;
    letter-spacing: 0em;
    text-align: left;
    .icon{
        width:1.375rem;
        height: 1.125rem;
    }

}
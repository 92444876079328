.whatwedo {
  user-select: none;
  background-color: $offwhite;
  &.h-900 {
    height: 900px;
  }
  p {
    font-family: "Barlow";
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.01em;
  }
  h1 {
    font-family: "Rubik";
    font-size: 2.125rem;
    color: $maroon;
    font-style: normal;
    font-weight: bold;
    line-height: 2.5rem;
    letter-spacing: 0.02em;
  }
  &.mobile {
    .illu {
      padding-bottom: 5vh;
    }
  }
  #plane {
    position: relative;
    animation: flyingPlane 14s ease-in-out infinite;
  }
}
.copy.mobile {
  height: max-content;
}
.copy.mobile h1 {
  font-family:"Rubik";
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.02em;
}
.whatwedo.land {
  height: 85vh;
}
.whatwedo-slide {
  user-select: none;
  background-color: $offwhite;
  min-height: 65vh;
  &.h-65vh {
    height: 65vh;
    min-height: auto;
  }
  .illu.edge {
    padding: 0px;
  }
  p {
    font-family: "Barlow";
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.01em;
  }
  h1 {
    font-family: "Rubik";
    font-size: 1.75vw;
    color: $maroon;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0.02em;
  }
  .empty {
    height: 10vh;
  }
  .illu-parallal {
    height: fit-content !important;
    padding: 0px !important;
  }
}
.whatwedo.mobile {
  user-select: none;
  .content.mobile {
    padding-top: 1%;
  }
  .illu {
    height: max-content;
  }
}
#butterfly-prison {
  width: 100%;
  height: fit-content !important;
}
.content.mobile {
  padding-right: 0px;
}

.team.paar{
    z-index:0;
    padding-top: 7.5vh; 
    padding-bottom: 7.5vh; 

    padding-right: 7.5vw; 
    // padding-left: 7.5vw; 
    min-height: 66vh;
    background-color: $offwhite;
    .content{

        padding-bottom: 5vh; 

    }

    // .team_paar_container{
    //     width: 100%;
    // }
    

    .card{
        background-color: $offwhite;
        border: 0px;
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $paar-pink;
        background-color: none;
        &:hover,&:focus{
            color: $maroon;
        }
        // height: 100%;


    }


    .team_details{
        position: static; /* this can also be static */
        height: max-content;
    }
 
    .card-img-top{
        width: 12.5rem;    
        height: 12.5rem;
        z-index: 1;
        // box-shadow: 0.75vw 0 $offwhite,
        // 0.8vw 0 $paar-pink; 
        // &::after{
        //     position:absolute;
        //     content: " ";
        //     min-width: 12.5rem;    
        //     min-height: 12.5rem;
        //     border: 2px;
        //     border-style: solid;
        //     border-color: $paar-pink;
        //     transform: translateX(20px) !important;

        // }
 
    
    }
    .img-row {
        width: fit-content;
        z-index: 1;
        &::after{
            position:absolute;
            content: " ";
            min-width: 12.5rem;    
            min-height: 12.5rem;
            // border: 1px;
            // border-style: solid;
            border-color: $paar-pink;
            //border-left: none;
            border-radius: 100%;
            transform:translateX(-180px);
            z-index: -1;

        
        }
    }
    .card img{
        border-radius: 50%;
        object-fit: cover;
        object-position: -1vw;
        z-index: 1;
        // &:after {
        //     content: "";	position: absolute;	width: 12.5rem;	height: 12.5rem;	top: 2vh;	left: 2vw;	z-index: 10;	background-color: $paar-pink;
        // }
        
    }
    .card img.shift{
        //object-position: -3vw;
        object-position: -40px;
        
    }
    .card img.negshift{
        //object-position: 0.020vw !important;
        object-position: 0.020em !important;
        
    }

    .card img.zoom{
        transform: scale(1.1);

        
    }
    .card img.bg{
        box-shadow: 
        2vw solid $paar-pink
        2.25vw solid $paar-pink;
        ::after{
            content: '';
            border: 2px solid $paar-pink !important;
            transform: translateX(1vw) !important; 
            box-shadow: 0.75vw 0 $offwhite,
                     0.8vw 0 $paar-pink;
        }
        
    }
    .card-text{
       //styleName: body_copy_3;
       font-family: Barlow;
       font-size: 1.25rem;
       font-style: normal;
       font-weight: 400;
       line-height: 2rem;
       letter-spacing: 0em;
       text-align: left;
       color: $black;

    }

    .card-subtitle{

        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $maroon;
        padding-bottom: 0.25em; 
        border-bottom: 0.02em solid $maroon;


    }
    .card-body{

        border:0px;
        padding-left:0px;
    }
    .card-title{
            
        font-family:"Rubik";
        font-size: 1.5rem;
      
        font-style: normal;
        font-weight: 700;
        line-height: 1.75rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;

    

    }

    .btn.btn-primary.details{
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $paar-pink;
        background-color: $offwhite;
        border-color: $offwhite;

        &:hover,&:focus{
            color: $maroon;
        }
        padding-left: 0px;
    
    
    }

}
.card-footer{
    background: none;
    border: none;
    padding: 0px;
}




@import "./slideshow";
@import "./slideshow2";
@import "./openprisonvoices";
@import "./redirectcards";

.twit{
    padding:15px;
    text-align: center;
    min-height: 53px;
    background: #FFFBED;
    span{
        font-family:"Rubik";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:#64002A;
    }
}
.donate{
    min-height: 100vh;
    
    background-color:$offwhite;
    padding-left: 0px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $maroon;
        opacity: 1; /* Firefox */
      }
    .body{
        
        $color:$maroon;
        h2{
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $maroon;
            padding-left: 0;
        }
        p{
            color: $black;
            padding:0;
            font-family: Barlow;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: normal;
            line-height:2.125rem;
            letter-spacing: 0em;
            text-align: left;
        }
    }
    // .input-group-text{
    //     background-color:$offwhite;
    //     border-color: $offwhite;
    //     //styleName: body_copy_small;
    //     font-family: Barlow;
    //     font-size:2rem;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 1.375rem;
    //     letter-spacing: 0em;
    //     text-align: left;

    // }
    span{
        color: $paar-pink;
    }


    .btn {

        background-color:$paar-pink;
        color: $offwhite;
        width:10vw;
        border-radius:3.125rem;
        border-color: $paar-pink;
        outline-color:$paar-pink;
        font-family: Barlow;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.688rem;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 1vw !important; 

    }

    .btn.disabled{
        background-color:$warm-gray;
        border-color: $warm-gray;
        outline-color:$warm-gray;
    }
    .donate-frequency-buttons{
        .btn-sm, .btn-group-sm > .btn{
            padding-right: 2rem;
        }
    }
    
    .btn-primary:hover{
        background-color:$maroon;
    }
    .empty{
        height: 5vh;
    }
    .form{
        padding-left: 0px;

        background-color:$offwhite;
        
        p{
            //styleName: body copy 2;
            font-family: Barlow;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem;
            letter-spacing: 0em;
            text-align: center;

        }

        .form-control{
            background-color:$offwhite;
            border-color:$maroon;        
            color: $maroon;
            border-radius:0rem;
            
        }
        .form-control:focus{
            background: rgba($offwhite, 0.25);
            border-color: rgba($light-gray, 0.25);
            box-shadow:  rgba($maroon, 0.25);
            
        }
        .caption{
            //styleName: caption;
            font-family: Libre Baskerville;
            font-size: 0.875rem;
            font-style: italic;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: 0em;
            text-align: left;

        }
    }

}
.slideotron{
        min-height: 104vh;
       
        height: max-content;
            .carousel{
                height:100%;
                padding: 0px;
            }
            .slide{
                min-height: 104vh;

            }
            .illus{
                min-height: 50%;
                // max-height: 75%;
            }
            .carousel-inner{
                height: 104%;
            }
            .slide1-copy{
                // padding-top:10em;
                // transform: translateX(-10rem) translateY(10rem);
            }
            .slide2-copy{
                

            }
            .slide3-copy{
                padding-top:10em;
                transform: translateX(-10rem) translateY(10rem);
            }
       
        
            .slide_parallax{
                //min-height: 75vh;
                background-attachment: fixed;
            }

        .carousel-indicators {
            background-color: "transparent";
            z-index: 100;
            transform: translateY(-5rem);
            button{
                height: 0.75rem;
                width: 0.75rem;
                z-index: 100;
            
            }
            
            [data-bs-target] {
                height: 0.75rem;
                width: 0.75rem;
                border-radius: 100%;
                background-clip: none;
                background-color: $paar-pink;
                margin-right: 1.688rem;
                opacity: 1.0 !important;
                z-index: 100;
                &:hover,
                &:focus {
                color: $medium-gray;
                
                }
            }
            
            [aria-current="true"]{
                background-color: $medium-gray;
                opacity: 1.0 !important;
                

            }
            .active{
                background-color: $medium-gray !important;
            }
        }
        color: $white;
        // .container{
        //     height: 100vh;
        // }
    
        .slide1-birds{
                
            width: 133px;
            height: 71px;
            transform:translateY(15.5em) !important; 
            #bird1{
                transform:translateX(5.5em) !important; 
            }
               
        
        }


        .slide2-clouds{
                
            width: 133px;
            height: 71px;
            transform:translateY(12.5em) translateX(2.5em) !important; 
    
        }
        .slideotron-illus 
        {
        
            #kite{
                width:35.188rem;
                transform:translateY(12.5em) translateX(-12.5em); 
                //animation: kiteFlying 7s ease-in-out infinite;
                position: relative;
                // .manja {
                //     transform: translate(-5px,15px);
                // }
                #kite-group {
                    position: relative;
                    transform-origin: 50% 50%;
                    animation: floatAnim 5s ease-in-out infinite;
                }
            }
            //padding-top: 15em;
        }
        // #cloud2{
        //     animation:  cloud-wiggle 10s infinite;

        // }
        // #cloud1{
        //     animation:  cloud-wiggle2 20s infinite;

        // }
        #bird21{
            animation:  bird-wiggle 5s infinite;

        }
        #bird11{
            animation:  bird-wiggle 5s infinite;

        }

       
        .slideotron-land {
            //font-size: 3.5vw;

            h3{
                font-family: "Libre Baskerville";

                font-weight: 700;
                font-style: normal;
                line-height: 3.75rem;
                font-size: 3.5rem;

            }
            p{
                font-family: "Libre Baskerville";

                font-weight: 400;
                font-style: bold;
                line-height: 3.75rem;
                font-size: 3.5rem;
                letter-spacing: 0.03em;


            }
            span {
                font-family: "Libre Baskerville";
                font-style: italic;
                font-weight: 400;
                color: $paar-pink;
                line-height: 3.75rem;
                font-size: 3.5rem;
                letter-spacing: 0.03em;
            }
            
        }

        .mobile {
            //font-size: 3.5vw;
            overflow-x: hidden;
            h3{
                font-family: "Libre Baskerville";

                font-weight: 700;
                font-style: normal;
                line-height: 3.75rem;
                font-size: 3.5rem;

            }
            p{
                font-family: "Libre Baskerville";

                font-weight: 400;
                font-style: bold;
                line-height: 2.5rem;
                font-size: 2.25rem;
                letter-spacing: 0.03em;


            }
            span {
                font-family: "Libre Baskerville";
                font-style: italic;
                font-weight: 400;
                color: $paar-pink;
                line-height: 2.5rem;
                font-size: 2.25rem;
                letter-spacing: 0.03em;
            }
            
        }
        
    
}
.slideotron.container-fluid{
    padding: 0px;
}


#bird1{
    transform: translateY(-10vh);
}

#bird2{
    transform: translateY(-7.5vh);
}



#bird1_xxl{
    position: absolute;
    animation: bird-wiggle 16s linear infinite;
    
}

#bird2_xxl{
    position: absolute;
    animation: bird-wiggle 24s linear infinite;

}

#cloud1{
    transform: translateY(-20vh);
}

#cloud2{
    transform: translateY(-7.5vh);
}

#cloud1_xxl{
    position: relative;
    margin-top: 12.5vh;
        width:75%;
    height: auto;
    //transform: translateY(-25vh);
    animation: cloud-wiggle2 26s ease-in-out infinite;
}

#cloud2_xxl{
    position: relative;
    margin-top: 12.5vh;
    width:75%;
    height: auto;
    //transform: translateY(-17.5vh);
    animation: cloud-wiggle3 26s ease-in-out infinite;
}

#Layer_1{  
    // transform:translateY(-2.5em) translateX(-5em); 
    //transform:translateY(12.5em) translateX(-12.5em); 
    // animation: kiteFlying 7s ease-in-out infinite;
    position: relative;
    #kite-group {
        position: relative;
        transform-origin: 50% 50%;
        animation: KitefloatAnim 5s ease-in-out infinite;
    }
}

#kite_xxl{
    width:350%;
    position: relative;
    //transform:translateY(10vh); //translateX(12.5em); 
    .manja {    
        transform: translate(-5px,15px);
    }

    #kite-group {
        position: relative;
        transform-origin: 50% 50%;
        animation: floatAnim 3s ease-in-out infinite;
    }
}

.slideotron-land.mobile{
    //padding-top:10vh;
    
}

.slideotron.mobile{
    padding-left:0px;
    padding-bottom: 0px;
    
}

.illus.anim{
    height: max-content;
}
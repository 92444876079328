.article{
    padding-left:0px;
  
    .title{
        height: 45vh;
        padding-top: 15vh;
        
        background-color: $offwhite;
        h1{
            font-family: Barlow;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 38px;
            /* or 119% */
            color: #64002A;
        }
        h2{
            font-family: Barlow;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 38px;
            /* or 119% */
            color: #64002A;
        }
        h3{
            font-family: Barlow;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 38px;
            /* or 119% */
            color: #64002A;
        }
        h4.author{
            font-family:"Rubik";
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;

/* PAAR_pink */

color: #EC1A75;
        }
        span.published{
            font-family: Barlow;
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 28px;
            color: #64002A;

        }


    }
    p{
        //styleName: body_copy_3;
font-family: Barlow;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #64002A;

    }
    li{
        //styleName: body_copy_3;
/* body copy */

font-family: Barlow;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 32px;
/* or 160% */


/* maroon */

color: #64002A;

    }
    h3{
        /* body copy */

font-family: Libre Baskerville;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 37px;
color: #64002A;

/* maroon */
    }
    a{
        /* body copy */

font-family: Barlow;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 32px;
/* or 160% */


/* maroon */

color: #64002A;
    }
    .img-citation{
       // padding: 0px;
       // margin: 0px;
/* footer menu items */

font-family:"Rubik";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.03em;

/* medium_grey */

color: #9F9A93;
    }
    .copy-block,.citations,.references{
        padding-top: 5vh;
        padding-bottom: 2.5vh;
        padding-left:0px;
        & .col{
            padding-left:0px;

        }

    }
    .copy-img{
        padding: 0px;
        margin: 0px;
        
        width: max-content;
        overflow-x: hidden;
        img{
            padding: 0px;
            margin: 0px;
            overflow-x: hidden;
             width: 99.1vw;
            // height: auto;
            // width: 100vw;
            // box-sizing: content-box;
                
        }
    }
}
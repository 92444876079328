.redirect-card-wrapper{
    width:100%;
    // min-height:850px;
    display: flex;
    padding: 100px 0;
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    // justify-content: space-around;
    @media screen and (max-width:970px) {
        padding:0px;
    }
    @media screen and (max-width:700px) {
        display:block;
        padding:0px;
    }
}
.redirect-card{
    width: 377px;
    height: 523px;
    margin: 20px 10px;
    border-radius: 15px;
    text-align: center;
    color: #64002A;
    position:relative;
    
    .next-icon{
        position: absolute;
        right: 40px;
        top: 40px;
    }

@media screen and (max-width:700px) {
    margin:20px auto;
    padding: 10px;
}
@media screen and (max-width:400px) {
    margin:20px auto;
    max-width:315px;
}

}
.redirect-title{
    font-family: "Barlow";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    
}
.redirect-content{
font-family: "Barlow";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 34px;
letter-spacing: 0em;
text-align: left;

}
.redirect-footer{
    position:absolute;
    bottom:10px;
    padding:40px;
    @media (min-width:700px) and (max-width:850px) {
        padding:20px;
    
    }
   
}

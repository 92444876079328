@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./style";

.App {
    scroll-behavior: smooth;
}

.App-logo {
}

@media (prefers-reduced-motion: no-preference) {
}


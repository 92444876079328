@import "../../colors";

.prisonsystem {
    user-select: none;
    background-color: $offwhite;
    height: 85vh;

    &.h-100vh {
        height: 600px;
    }
    // max-height:  100vh;
    .empty{
        height: 2.5vh;
    }
    h1 {
        font-family: "Rubik";
        font-size: 2.125rem;
        color:$maroon;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
        letter-spacing: 0.02em;
    }
    p {
        font-family: "Barlow" !important;
        font-weight: "normal" !important;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2.125rem;
        letter-spacing: 0.01rem;
        

    }

    #p-flower{
            #d_float_5 *{
                animation:  dandelion 5s infinite;
        }
        #d_float_2 *{
            animation:  dandelion 5s infinite;
        }
        #d_float_3 *{
            animation:  dandelion 5s infinite;
        }
        #d_float_4 *{
            animation:  dandelion 5s infinite;
        }

        #d_float_7 *{
            animation:  dandelion 5s infinite;
        }
    
    
    }
    .illu{
     //   padding-left: 0px;
    }


}

.prisonsystem.mobile{
    // .copy{
    //     height:  max-content;    
    // }
    height:  fit-content;
    //height:
    //max-height: max-content;
    //padding-left: 0px;
    padding-bottom: 5vh;
}

.copy.mobile{
   height: fit-content;
   padding-bottom: 5vh;
}

.content.mobile{
    //padding-right:0px;
}

// Hand Flower Animation
#hand-flower .flower-petals {
    opacity: 0;
    animation: paar-fadein 2s linear alternate infinite;
    animation-fill-mode: both;
    &:nth-child(1) { animation-delay: .5s }
    &:nth-child(2) { animation-delay: 1s }
    &:nth-child(3) { animation-delay: 1.5s }
    &:nth-child(4) { animation-delay: 2s }
    &:nth-child(5) { animation-delay: 2.5s }
    &:nth-child(6) { animation-delay: 3s }
    &:nth-child(7) { animation-delay: 3.5s }
}

@import "./slideotron";
@import "./herotron";
@import "./prisonsystem";
@import "./whatwedo";
@import "./ourstory";
@import "./cycleofchange";
@import "./mission";
@import "./paaraccordian";


.carousel-vert > .carousel-indicators {
        background-color: "transparent";        
}   

.parallax{
        //min-height: 75vh;
        background-attachment: fixed;
}
.mobile{
	
}
.mobile .content{
        padding-right:0px;
        margin-right:0px;

}


.l-xl{

        // min-height: fit-content;
        .content {
                // min-height: fit-content;
                // max-height: max-content;
        }
}

.line-wrap {
    position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -300px;
	    height: 200px;
		width: 1px;
		background-color: $maroon;
	}
	&.xl-left-line {
		&:before {
			bottom: -260px;
			left: -25%;
		}
	}
	&.lg-left-line {
		&:before {
			height: 150px;
			bottom: -300px;
		}
	}
}

.opv-title{
    font-family: "Barlow";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color:maroon;
    margin-bottom: 40px;

    @media screen and (max-width:600px) {
        font-family: "Rubik";
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.02em;
        text-align: left;

    }
}

.opv-wrapper{
    display:grid;
    grid-template-columns: auto auto auto auto;
    grid-gap:auto;
    grid-row-gap:20px;
    grid-column-gap:20px;
    overflow: auto;
    @media screen and (max-width:1400px) {
        grid-gap:10px;
    }
    @media screen and (max-width:600px) {
        display:grid;
        grid-template-columns: auto auto auto;
        // repeat(auto-fit, minmax(180px, 1fr));;
        overflow: auto;
        grid-gap:10px;
    }
}
.opv-container{
    padding:100px 150px;
    background-color: #F3E6D5;
    @media screen and (max-width:1348px) {
        padding:80px 34px;
    }
}
.opv-image{
    width: auto;
    height: 0;
    padding-bottom: 100%;
}
.paaraccordion {
  background-color: $white;
  user-select: none;
  .accord-header {
    text-align: left;
    writing-mode: vertical-rl;
    border-right-width: 1px !important;
    border-right-color: $maroon !important;
    border-right-style: solid !important;
    transform: rotate(-180deg);
    justify-content: right;
  }
  h1 {
    font-size: 3.5rem;
    color: $maroon;
    font-family: "Libre Baskerville";
    font-style: normal;
    line-height: 4.375rem;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: left;

    span {
      color: $paar-pink;
    }
  }

  .info-accordion {
    padding-top: 2.5%;
  }
  a {
    text-decoration-color: $white;
  }

  h2 {
    font-size: 0.875rem;
    color: $paar-pink;
    font-family: "Rubik";
    font-weight: normal;
    letter-spacing: 0.03em;
    line-height: 0.875rem;
    font-style: normal;
    text-transform: uppercase;
  }
  .hidden-content {
    width: 0;
    height: 0;
  }

  .hidden-header {
    width: 0;
    height: 0;
  }
  h3 {
    font-family:"Rubik";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: $paar-pink;
    text-transform: uppercase;
  }

  .accord-header-content {
    justify-content: right;
    flex-direction: row-reverse;
  }

  .accordion-content {
    border-left-width: 1px !important;
    border-left-color: $maroon !important;
    border-left-style: solid !important;
    padding-left: 2%;
  }

  p {
    font-family: Barlow;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 0em;
    text-align: left;
  }
}

.paaraccordion.mobile {
  user-select: none;
  height: max-content;
  .h1-m {
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: 0.03em;
  }
  .mobile-h3 {
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: 0.03em;
  }
}

.carousel.mobile {
  user-select: none;
  overflow-x: hidden;
  padding-right: 0px;
  .card {
    .card-title.mobile-h3 {
      font-family:"Rubik";
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: 0.02em;
      color: $paar-pink;
    }
  }
}
.header.mobile {
  padding-left: 2.25vw;
}


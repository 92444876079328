/* Page content */
/* The sticky class is added to the header with JS when it reaches its scroll position */

.sticky {
    position: fixed;
    top: 0;
    width: 100vw;
    //display:flex !important;
    //flex-direction: row !important;
}
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    padding-top: 0vh;
  }
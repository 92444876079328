
.chart-container{
    background-color: $light-gray;
    text-align: center;
    padding-top: 70px;
    // height: 710px;
    svg {
        width: 680px;
        height: 640px;
    }
    .bubble-chart {
        transform: translate(0,0);
    }
    .filter-styles{
        display:flex;
        flex-wrap: wrap;
        padding:40px;
        // justify-content: space-between;
        @media screen and (max-width:600px) {
            padding: 40px 20px;
        }
        .filter-elements{
            flex-grow: 1;
            flex-shrink: 1;
            min-width:10px;
            margin:5px 10px;
            padding:5px 10px;
            border-radius: 30px;
            font-family: "Libre Baskerville";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color:$white;
            cursor: pointer;
        }
        .active{
            background-color: $paar-pink;
        }
    }
}
.category-styles{
    display:flex;
    flex-wrap: wrap;
    padding: 55px 168px 0px;
    // justify-content: space-between;

    @media screen and (max-width:600px) {
        padding:50px 30px 0px;
    }
    @media screen and (max-width:940px) {
        padding:50px 20px 0px;
    }

    .category-elements{
        font-family: "Barlow";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color:$maroon;
        background-color: $white-3;
        padding:12px 22px;
        border-radius: 30px;
        margin:0px 5px;
        // flex-grow: 1;
        flex-shrink: 1;
        margin-bottom: 25px;
        cursor: pointer;
    }
    .active{
        color:$white;
        background-color: $paar-pink;
    }
}
.dropdown{
.open-button{
    font-family: "Rubik";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color:$maroon;
    display:inline-block;
    cursor: pointer;
    padding:28px 20px 0px 30px;
}

.dropdown-icon{
    display:inline-block;
    cursor: pointer;
    
    transform: rotate(90deg);
    img{
        width:8px;
        height:17px;
    }
}
.close-button{
    float:right;
    background-color: $paar-pink;
    cursor: pointer;
    width:53px;
    height:53px;
    text-align: center;
    padding-top: 12px;
}
}
.active-cj-dropdown{
    background-color: $dropdown;
}
.cj-dropdown-list{
    padding-top: 28px;
    background-color: $dropdown;
    width: calc(100% - 53px);
    .category-elements{
        font-family: "Barlow";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color:$maroon;
        border-bottom: 1px solid $maroon;
        padding:20px 35px;
        cursor: pointer;

       
    }
    .selected-element{
        color:$white-3;
        background-color: $maroon;
    }
    .dropdown-show-results{
        background-color: $paar-pink;
        font-family: "Rubik";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:$dropdown;
        padding:20px;
        text-align: center;
        cursor: pointer;
    }
}


.header-car{
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-align: left;
    color:#64002A;
    padding-left: 10px;
}
.carousel{
    padding-left: 10px;
    padding-right: 10px;
    .paar-carousel-icon{
        path{
            stroke:$paar-pink;
        }
    }
    .carousel-item{
        min-height: 85vh;

       a{
           border-color: none !important;
           text-decoration-style:none;
           color: #fff !important;
           text-decoration-line:none;
       }

        .carousel-caption{
            top:33.333%;
            left: 5%;
            h1{
                font-family: Libre Baskerville;
                // font-size: 64px;
                font-style: normal;
                font-weight: normal;
                color: $white;
                // line-height: 90px;
                letter-spacing: 0.03em;
                text-align: left;
                font-size:4rem;
                line-height: 5.625rem;
    
            }
            h3,p{
                font-family: Libre Baskerville;
                font-style: italic;
                font-weight: normal;
                letter-spacing: 0em;
                text-align: left;
                color: $white;
    
            }
           

        }
       &::after{
        content: "";
        background-image: repeating-linear-gradient( 90deg, #fff, 
        #fff 20px, 
        $maroon 20px, 
        $maroon 40px) !important;
        width: 100%;
        height: 100%;
        box-sizing: unset;
        -webkit-box-sizing: unset;
        
        //background-image: url("/imgs/illustrations/bg-gradient.svg") 
        
        //box-sizing: content-box;
        // min-height: !important;
       }
    }
    // .carousel-item:before{
    //     background: repeating-linear-gradient( 90deg, #fff, 
    //                                                 #fff 20px, 
    //                                                 $maroon 20px, 
    //                                                 $maroon 40px) !important;

    //     min-height: 100%;
    // }
    padding-bottom: 5vh;
}
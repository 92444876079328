.login{
    background-color:$light-gray;
    height: 100vh;
    //padding-top:30vh;
    
    
    .body{
        padding-top:30vh;
      
        h2{
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $white;
        }
        p{
            color: $white;
            padding:0;
            font-family: Barlow;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.5rem;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    span{
        color: $paar-pink;
    }


    .btn {

        background-color:$paar-pink;
        color: $offwhite;
        width:10vw;
        border-radius:3.125rem;
        border-color: $paar-pink;
        outline-color:$paar-pink;
        font-family: Barlow;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.688rem;
        letter-spacing: 0em;
        text-align: center;
    }
    .btn-primary:hover{
        background-color:$maroon;
    }
    .form{
        background-color:$light-gray;    
        padding-top:30vh;
        
        height: 100vh;
        h2{
            font-family: Libre Baskerville;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 4.375rem;
            letter-spacing: 0.03em;
            text-align: center;
            color: $maroon;
        }

        p{
            //styleName: body copy 2;
            font-family: Barlow;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem;
            letter-spacing: 0em;
            text-align: center;

        }

        .form-control{
            background-color:$light-gray;
            border-color:$maroon;        
            color: $maroon;
            border-radius:0rem;
            
        }
        .form-control:focus{
            background: rgba($light-gray, 0.25);
            border-color: rgba($light-gray, 0.25);
            box-shadow:  rgba($maroon, 0.25);
            
        }
    }

}
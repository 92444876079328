.slideshow2 {
  user-select: none;
  min-height: 850px;
  overflow: hidden;
  padding-right: 0;
  padding-left: 0px;
  width: 100%;
  @media screen and (max-width: 600px) {
    min-height: 450px;
    .mobile-text-box {
      background: $paar-pink-light;
      padding-left: 46px;
      .text {
        font-family: "Libre Baskerville";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 50px;
        color: white;
      }
    }
  }
  @media screen and (min-width: 992px) {
    height: 850px;
    min-height: auto;
    .court-order {
      padding: 0px !important;
      left: 0px;
      top: 45px;
      background: $paar-pink;
      .text-box {
        margin-left: 96px;
        margin-top: 72px;
        width: 351px;
      }
      .text {
        font-family: "Libre Baskerville";
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 60px;
        color: white;
      }
      .kite {
        padding-left: 150px;
      }
    }
  }
  .navigation-buttons {
    margin-top: 300px;
    button {
      background-color: transparent;
      border: none;
    }
    .prev-button {
      padding-right: 35px;
    }
  }
  .slideshow2-row {
    padding-left: 0px !important;
  }
  .slideshow2-carousel {
    align-items: center;
    justify-content: center;
  }
  .carousel {
    height: 100%;
    padding: 0px;
  }
  .slide {
    min-height: 850px;
    @media screen and (min-width: 992px) {
      height: 850px;
      min-height: auto;
    }
    @media screen and (max-width: 600px) {
      min-height: 460px;
    }
  }
  .carousel-inner {
    height: 100%;
  }

  .slide3-copy {
    padding-top: 10em;
    transform: translateX(-10rem) translateY(10rem);
  }
  .slideshow2-slide-wrapper {
    width: 100%;
    height: 100%;
  }

  .slide_parallax {
    background-attachment: fixed;
  }
  .carousel-control-prev-icon {
    background-image: $paar-carousel-control-prev-icon-bg;
  }
  .carousel-control-next-icon {
    background-image: $paar-carousel-control-next-icon-bg;
  }
  a.carousel-control-prev {
    left: -25px;
    @media screen and (max-width: 600px) {
      left: -6px;
    }
  }
  a.carousel-control-next {
    right: 25px;
    @media screen and (max-width: 600px) {
      right: 15px;
    }
    @media screen and (min-width: 1300px) {
      right: 80px;
    }
  }
  .carousel-indicators {
    z-index: 100;
    margin-bottom: 50px;
    button {
      height: 0.75rem;
      width: 0.75rem;
      z-index: 100;
    }

    [data-bs-target] {
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 100%;
      background-clip: none;
      background-color: $paar-pink;
      margin-right: 1.688rem;
      opacity: 1 !important;
      z-index: 100;
      &:hover,
      &:focus {
        color: $medium-gray;
      }
    }

    [aria-current="true"] {
      background-color: $medium-gray;
      opacity: 1 !important;
    }
    .active {
      background-color: $medium-gray !important;
    }
  }

  .slideotron-land {
    padding-bottom: 115px;
    padding-top: 35px;
    @media screen and (max-width: 992px) {
      padding-top: 115px;
    }
    @media screen and (max-width: 600px) {
      padding: 40px 20px 115px 10px;
    }
    @media screen and (min-width: 1200px) {
      width: 1000px;
      margin: 0 auto;
    }
    .slide1 {
      @media screen and (max-width: 600px) {
        margin-top: 20%;
        padding-bottom: 70px;
      }
    }
    p {
      font-family: "Libre Baskerville";
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: $maroon;
      @media screen and (max-width: 600px) {
        font-size: 22px;
        line-height: 33px;
      }
      @media screen and (min-width: 1200px) {
        position: absolute;
        padding: 50px;
        top: 50%;
        left: 50%;
        width: 1000px;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.mission {
  user-select: none;
  background-color: $white;
  height: 100vh;
  font-style: normal;
  .content {
    padding-top: 0;
  }
  h4 {
    font-size: 1.75vw;
    color: $maroon;
    font-family: "Rubik";
    font-weight: "bold";
    vertical-align: "bottom";
  }
  .copy {
    padding-top: 5vh;
    h1 {
      color: $maroon;
      color: $maroon;
      font-family: "Rubik";
      font-weight: "bold";
      vertical-align: "bottom";
    }
  }
  p {
    color: $maroon;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    font-size: 3.5rem;
    line-height: 5rem;
    font-family: "Libre Baskerville";
  }
  .illu {
    width: fit-content;
    height: auto;
    overflow: hidden;
    &.mobile {
      padding-bottom: 2.5%;
    }
  }
}
.mission.mobile {
  user-select: none;
  height: max-content;
  padding-left: 0px;
  min-height: 95vh;
  overflow-x: hidden;
  h4 {
    padding-top: 1vh;

    font-family:"Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0.02em;
  }
}
.illu.mobile {
  height: 50vh;
}

P#tag-mobile {
  padding-top: 1vh;
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2.5rem;
  padding-bottom: 2vh;
  letter-spacing: 0.03em;
}

.tag {
  padding-right: 0px;
  margin-right: 0px;
}

.illu.mobile.large {
  width: 50%;
}
#kite-illu-mission {
  #pink-kite {
    position: relative;
    transform-origin: 50% 50%;
    animation: KitefloatAnim2 5s ease-in-out infinite;
  }
  #Group_97 {
    transform: translate(-4px, -6px);
  }
}

.mission.mobile {
  .copy {
    height: max-content;
  }
  height: max-content;
}

.career{
    padding-left: 0px;
    margin-left: 0px;
    .body{
        font-family: Barlow;
        font-size: 1.25px;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0em;
        text-align: left;

    }
    .header{
        font-family: Barlow;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3.125rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;

    }

    .info{
        padding-right: 0px;
        margin-right: 0px;
        .copy{
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;
            height: fit-content;

        }

    }
    .carousel-control-prev {
        width: max-content;
        left: -5%;
    }
    .carousel-control-next {
        width: max-content;
        right: -1%;
    }
    background-color: $offwhite;
    .banner.mobile{
        background-color: $warm-gray;
        overflow-x: hidden;
    }
    .banner{
        overflow-x: hidden;
        background-color: $warm-gray;
        .empty{
            height: 15vh;
        }
        span{
            color: $paar-pink;
        }

        .banner-copy.mobile{
            padding-left: 0px;
            padding-top: 5em;
            h2{
                font-family: Libre Baskerville;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: bold;
                line-height: 2.5rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
            p{
                font-family: Barlow;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: normal;
                line-height: 2.125rem;
                letter-spacing: 0.01em;
                text-align: left;
                color: $white;
            }
        }
        .banner-copy{
            padding-left: 0px;

            padding-top: 10em;
            h2{
                font-family: Libre Baskerville;
                font-size: 3.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
            p{
                font-family: Barlow;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.5rem;
                letter-spacing: 0em;
                text-align: left;
                color: $white;
            }
        }
    }

       h2{
                font-family: Libre Baskerville;
                font-size: 3.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem;
                letter-spacing: 0.03em;
                text-align: left;
                color: $maroon;
            }
    .info .copy{
        padding-top: 5em;
        padding-bottom: 5em;

    }
    .job-cards-heading{
        padding-bottom: 5em;
    }
    .job-cards-heading.mobile{
        h2{
            font-family: Libre Baskerville;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: bold;
            line-height: 2.5rem;
            letter-spacing: 0.03em;
            text-align: left;
            color: $maroon;
        }
        p{
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;
            color: $white;
        }
    }
    .card{
        padding-left: 0px;

    }
    .card-subtitle{
        padding-bottom: 2.5em;
    }
    .info .copy p {
        font-size: 1.5rem;
        color: $black;
        font-family: "Barlow";
        font-weight: 400;
        color: $black;
        color: $maroon;


    }
    .career-cards .empty{
        height: 5vh;
    }
    .career-openings .card{
        padding-left: 0px;

        background-color: $offwhite;
        border: 0px;
        a {
            color: $paar-pink !important;
        }

        .card-title{
            font-family:"Rubik";
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.75rem;
            letter-spacing: 0.02em;
            text-align: left;
            color:$maroon;

        }
        .card-body{
            padding-left: 0px;
        }
        .card-subtitle{
            font-family: Barlow;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem;
            letter-spacing: 0.01em;
            text-align: left;

            color:$maroon;
            padding-bottom: 0.25em;
            border-bottom: 0.02em solid $maroon;


        }
    }


    .empty{
        height: 10vh;
        padding-right: 0px;
        margin-right: 0px;
    }
    .download{
        padding-top:10em;
    }
    .btn.btn-primary.details{
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $paar-pink;
        background-color: $offwhite;
        border-color: $offwhite;
        border:0px;

        &:hover,&:focus{
            color: $maroon;
        }
        padding-left: 0px;
    }
}

#banner-career-illu{
    padding-top:3.5em;
    height: auto;
    width:75%;
}

#banner-career-illu-mobile{
    height: auto;
    width:100%;

}

.mobile{
    padding-right: 0px;
    margin-right: 0px;
}
#puzzle1 {
    position: relative;
    transform-origin: 50% 50%;
    animation: puzzlefloatAnim 8s ease-in-out infinite;
}
#puzzle2 {
    position: relative;
    transform-origin: 50% 50%;
    animation: puzzlefloatAnim 7s ease-in-out infinite;
}
#puzzle3 {
    position: relative;
    transform-origin: 50% 50%;
    animation: puzzlefloatAnim 6s ease-in-out infinite;
}
@media only screen and (max-width: 576px) {
    h2{
        font-size: 3.0rem !important;
    }
    .copy{
        padding-top: 2rem !important;
        padding-bottom: 0 !important;
    }
    .job-cards-heading{
        padding-bottom: 2rem !important;
    }
    .banner-copy{
        padding-top: 5rem !important;
    }
    .career-cards h2{
        font-size: 2.0rem !important;
    }
    .job-cards-heading{
       display: inline !important;
    }
}

.team.donors{
    padding-top: 7.5vh; 
    padding-bottom: 7.5vh; 
    padding-right: 7.5vw; 
    min-height: 66vh;

    .title{
        background-color: $warm-gray;
    }
    background-color: $warm-gray;
    .donor_details{
        position: static; /* this can also be static */
    }
    .card-body{
        user-select: none;
        border:0px;
        padding-left:0px;
    }

    .card{
        background-color:  $warm-gray;
        border: 0px;
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $paar-pink;
        background-color: none;
        &:hover,&:focus{
            color: $maroon;
        }





    }

    
    .card-img-top{
        width: 12.5rem;    
        height: 12.5rem;
    }
    
    .card img.shift{
        object-position: 0 0.025vh;
    }
    .card img{
        border-radius: 50%;
        object-fit: cover;

    }
    .card a{
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $white;

        &:hover,&:focus{
            color: $offwhite;
        }

    }
    .card-text{
       //styleName: body_copy_3;
       font-family: Barlow;
       font-size: 1.25rem;
       font-style: normal;
       font-weight: 400;
       line-height: 2rem;
       letter-spacing: 0em;
       text-align: left;
       color: $black;
       width: 100%;
    }
    .card-subtitle{

        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $maroon;
        padding-bottom: 0.25em; 
        border-bottom: 0.02em solid $maroon;
    }
    .card-title{
            
        //styleName: team name;
        font-family:"Rubik";
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.75rem;
        letter-spacing: 0.02em;
        text-align: left;
        color: $maroon;
        flex-direction: column;
        flex-grow: 1;
        //height: 2.25em;

    }

    .btn.btn-primary.details{
        //styleName: body copy_1;
        font-family: Barlow;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.125rem;
        letter-spacing: 0.01em;
        text-align: left;
        color: $white;
        background-color: $warm-gray;
        border-color: $warm-gray;
        border:0px;
        
        
        &:hover,&:focus{
            color: $maroon;
        }
        padding-left: 0px;
    
    }

}


.slideshow {
    height: max-content;
    overflow: hidden;
    padding-right: 0;
    padding-left: 0px;
    padding-top: 20px;
    .carousel{
        height:100%;
        background-color: rgb(243, 230, 213);
    }
    .slide{
        min-height: 790px;
        .carousel-inner {
            @media screen and (min-width: 992px) {
                max-width: 820px;
                margin: 0 auto;
            }
            @media screen and (min-width: 1200px) {
                max-width: 1040px;
            }
            @media screen and (min-width: 1550px) {
                max-width: 1190px;
            }
        }
        .alignment{
            margin-bottom: 120px;
            padding: 20px;
            width: 90%;
            margin-left: auto;
           
            @media screen and (max-width: 992px) {
                width: 73%;
            }
            @media screen and (max-width: 767px) {
                width: 95%;
                margin-bottom: 60px;
                padding: 40px 20px;
            }
        }
        @media screen and (max-width: 767px) {
            min-height: 540px;
        }
    }
    .illus{
        @media screen and (min-width:992px) {
            margin-bottom: 100px;
            text-align: right;
        }
        img{
            width: 280px;
            display: block;
            margin: auto;
            &#slide4 {
                height: 289px;
            }
            @media screen and (min-width:992px) {
                width: 400px;
                height: 412px;
                vertical-align: bottom;
                &#slide4 {
                    height: 412px;
                }
            }
            @media screen and (min-width:1200px) {
                width: 529px;
                vertical-align: bottom;
                height: auto;
                &#slide4 {
                    height: 545px;
                }
            }
        }
    }
    .carousel-inner{
        height: 104%;
    }

    .slide_parallax{
        background-attachment: fixed;
    }
    .slide-img {
        @media (min-width: 992px) and (max-width: 1300px) {
            width: 400px;
        }
    }
    .carousel-indicators {
        z-index: 100;
        margin-bottom: 50px;
        button{
            height: 0.75rem;
            width: 0.75rem;
            z-index: 100;
        
        }
        [data-bs-target] {
            height: 0.75rem;
            width: 0.75rem;
            border-radius: 100%;
            background-clip: none;
            background-color: $paar-pink;
            margin-right: 1.688rem;
            opacity: 1.0 !important;
            z-index: 100;
            &:hover,
            &:focus {
            color: $medium-gray;
            }
        }
        [aria-current="true"]{
            background-color: $medium-gray;
            opacity: 1.0 !important;
        }
        .active{
            background-color: $medium-gray !important;
        }
    }
    a.carousel-control-prev{
        left:-15px;
    }
    a.carousel-control-next{
        right: 40px;
        @media screen and (min-width: 1200px) {
            right: 70px;
        }
        @media screen and (min-width: 1550px) {
            right: 100px;
        }
    }
    .carousel-control-prev-icon{
        background-image: $paar-carousel-control-prev-icon-bg;
    
    }
    .carousel-control-next-icon{
        background-image: $paar-carousel-control-next-icon-bg;
    }

    .slideotron-land {
        @media (min-width: 992px) and (max-width: 1200px) {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 470px) {
            br {
                display: none;
            }
        }
        h3{
            font-family: "Libre Baskerville";
            font-weight: 700;
            font-style: normal;
            line-height: 3.75rem;
            font-size: 3.5rem;
        }
        p, .p-tag{
            font-family: "Libre Baskerville";
            font-size: 56px;
            font-weight: bold;
            line-height: 70px;
            letter-spacing: 0.03em;
            color: $maroon;
            @media screen and (max-width: 1300px) {
                font-size: 35px;
                line-height: 50px;
            }

        }
        span {
            font-family: "Libre Baskerville";
            font-weight: 400;
            color: $paar-pink;
            line-height: 3.75rem;
            font-size: 3.5rem;
            letter-spacing: 0.03em;
            @media screen and (max-width: 1300px) {
                line-height: 2.75rem;
                font-size: 2.5rem;
            }
        }
        
    }

    .mobile {
        overflow-x: hidden;
        h3{
            font-family: "Libre Baskerville";
            font-weight: 700;
            font-style: normal;
            line-height: 3.75rem;
            font-size: 3.5rem;
        }
        p, .p-tag{
            font-family: "Libre Baskerville";
            font-style: bold;
            font-weight: 700;
            line-height: 2.25rem;
            font-size: 1.875rem;
            letter-spacing: 0.03em;
            margin-bottom: 0;
        }
        span {
            font-family: "Libre Baskerville";
            font-style: bold;
            font-weight: 700;
            color: $paar-pink;
            line-height: 2.25rem;
            font-size: 1.875rem;
            letter-spacing: 0.03em;
        }
        
    }
}
.slideotron.container-fluid{
    padding: 0px;
}
.hero-slideshow {
    .carousel-inner .slideshow {
        @media screen and (max-width: 992px) {
            padding-top: 75px;
        }
    }
}


